// ボタン

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";
@import "../arrow-mixin";

// ==============================================
// スタイル
// ==============================================
// ボタンの矢印
// ==============================================
%a-btn-arrow {
    &::before {
        content: '';
        display: block;
        position: absolute;
        @include centering(y, -45);
        width: 10px;
        height: 10px;
        border-right: 2px solid $base-color;
        border-bottom: 2px solid $base-color;
        box-sizing: border-box;
    }
}

// 左向き
%a-btn-arrow--left {
    @extend %a-btn-arrow;

    &::before {
        @include centering(y, 135);
    }
}

// 下向き
%a-btn-arrow--down {
    @extend %a-btn-arrow;

    &::before {
        @include centering(y, 45);
    }
}

// ボタンベース
// ==============================================
%a-btn-base {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 320px;
    min-height: 40px;
    margin: auto;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    overflow: hidden;
    @include hover;

    &.forward {
        @extend %a-btn-arrow;
        padding: 10px 34px 10px 14px;

        &::before {
            right: 12px;
        }
    }

    &.back {
        @extend %a-btn-arrow--left;
        padding: 10px 14px 10px 34px;

        &::before {
            left: 12px;
        }
    }

    &.down {
        @extend %a-btn-arrow--down;
        padding: 10px 34px 10px 14px;

        &::before {
            top: 42%;
            right: 12px;
        }
    }
}

// ボタン
// ==============================================
@mixin basic-btn {
    @extend %a-btn-base;
    background-color: #fff;
    border: 1px solid $border-color;
    font-size: 14px;
    padding: 11px 0;
    line-height: 1.2;

    &:active, &:focus, &:visited, &:link {
        color: $base-color;
    }

    .ico {
        display: inline-block;
        padding-right: 8px;
        font-size: 16px;
        font-weight: normal;
        vertical-align: middle;

        &::before {
            display: block;
        }

        // アイコンの微調整
        @extend %a-btn-ico-adjust;
    }

    .ico-txt {
        vertical-align: middle;
    }

    span {
        color: inherit;
    }

    // 無効時
    &:disabled, &.disabled {
        color: #ccc;
        background: #eef0f2;
        border: 1px solid $border-color;
        pointer-events: none;
        box-shadow: none;
        padding: 11px 0;
        cursor: auto;

        span,.ico,.ico-txt {
            color: #ccc;
        }
    }
}

.a-btn, .a-basic-btn, %a-btn {
    @extend %a-btn-base;
    background-color: #fff;
    border: 1px solid $border-color;
    font-size: 14px;
    padding: 11px 0;
    line-height: 1.2;

    &:active, &:focus, &:visited, &:link {
        color: $base-color;
    }

    .ico {
        display: inline-block;
        padding-right: 8px;
        font-size: 16px;
        font-weight: normal;
        vertical-align: middle;

        &::before {
            display: block;
        }

        // アイコンの微調整
        @extend %a-btn-ico-adjust;
    }

    .ico-txt {
        vertical-align: middle;
    }

    span {
        color: inherit;
    }

    // 無効時
    &:disabled, &.disabled {
        color: #ccc;
        background: #eef0f2;
        border: 1px solid $border-color;
        pointer-events: none;
        box-shadow: none;
        padding: 11px 0;
        cursor: auto;

        span,.ico,.ico-txt {
            color: #ccc;
        }
    }
}

// お気に入り追加
.a-basic-btn--favorite-add {
    @extend %a-btn;
    color: $base-color;

    &:active, &:focus, &:visited, &:link {
        color: $base-color;
    }

    span {
        color: $base-color;
    }
}

// お気に入り追加済み
.a-basic-btn--favorite-added {
    @extend %a-btn;
    box-shadow: none;
    color: $active-color;

    &:active, &:focus, &:visited, &:link {
        color: $active-color;
    }

    span {
        color: $active-color;
    }
}
// EPUBダウンロード
.a-basic-btn--epub-dl {
    @extend %a-btn;
    color: $base-color;

    &:active, &:focus, &:visited, &:link {
        color: $base-color;
    }

    span {
        color: $base-color;
    }
}

// 登録済み・チェック済み
.a-basic-btn--registered,
.a-basic-btn--checked {
    @extend %a-btn;
    color: $active-color;

    &:active, &:focus, &:visited, &:link {
        color: $active-color;
    }

    span {
        color: $active-color;
    }
}

// カートを見る
.a-basic-btn--cart-on {
    @extend %a-btn;
    border: 1px solid $cart-btn-color;
    box-shadow: none;
    color: $cart-btn-color;

    &:active, &:focus, &:visited, &:link {
        color: $cart-btn-color;
    }

    span {
        color: $cart-btn-color;
    }
}

// 削除
.a-basic-btn--delete {
    @extend %a-btn;
    color: $strong-red;
    padding: 10px 0;

    &:active, &:focus, &:visited, &:link {
        color: $strong-red;
    }

    span {
        color: $strong-red;
    }

    .ico-close {
        font-size: 20.5px;
        padding-right: 5px;
    }

    &:disabled, &.disabled {
        padding: 10px 0;
    }
}

// 試し読み、読むボタン（ニコニコ書籍で読む）
.a-basic-btn--trial,
.a-basic-btn--read,
.a-basic-btn--follow {
    @extend %a-btn;
}

// 色付きのボタン
// ==============================================
@mixin color-btn {
    @extend %a-btn;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.4) inset;
    border: none;
    color: #fff;
    padding: 12px 0;

    span,.ico {
        color: #fff;
    }

    .ico {
        font-weight: normal;
    }
}

// 標準ボタン　第1カラー
.a-basic-btn--primary {
    @include color-btn;
    background-color: $primary-color;
    box-shadow: 0 1px 0 0 rgba(186, 109, 32, 1);

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 標準ボタン　第2カラー
.a-basic-btn--secondary {
    @include color-btn;
    background-color: $secondary-color;
    box-shadow: 0 1px 0 rgba(61, 87, 73, 1);

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 予約　標準ボタン
.a-basic-btn--reserve {
    @include color-btn;
    background-color: $strong-blue;
    box-shadow: 0 1px 0 0 rgba(8, 44, 81, 1);

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// ギフト　標準ボタン
.a-basic-btn--gift {
    @include color-btn;
    background-color: $gift-btn-color;
    box-shadow: 0 1px 0 0 rgba(13, 45, 28, 0.8);

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 無料で読む
.a-basic-btn--free {
    @include color-btn;
    background-color: $free-key-color;
    box-shadow: 0 1px 0 0 #a30f0f;

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// まる読み
.a-basic-btn--maruyomi {
    @include color-btn;
    background-color: $maruyomi-btn-color;
    box-shadow: 0 1px 0 0 #dd3173;

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 話読みで読む
.a-basic-btn--warensai {
    @include color-btn;
    background-color: $ttsk-blue-color;
    box-shadow: 0 1px 0 0 $ttsk-dark-blue-color;

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 無料会員登録（オレンジ）
.a-basic-btn--member-registration {
    @include color-btn;
    background-color: $sign-in-btn-color;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.4);

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// 色付きのボタンのカラー反転版
// ==============================================
@mixin color-btn-rev {
    @extend %a-btn;
    background-color: #fff;
    border: 1px solid;
    box-shadow: none;
}

// 標準ボタン　第1カラー反転版
.a-basic-btn--primary-rev {
    @include color-btn-rev;
    color: $primary-color;

    &:active, &:focus, &:visited, &:link {
        color: $primary-color;
    }

    .ico {
        color: $primary-color;
    }
}

// 標準ボタン　第2カラー反転版
.a-basic-btn--secondary-rev {
    @include color-btn-rev;
    border-color: $secondary-color;
    color: $secondary-color;

    &:active, &:focus, &:visited, &:link {
        color: $secondary-color;
    }

    .ico {
        color: $secondary-color;
    }
}

// 影なし枠線付きボタン
// ==============================================
.a-border-btn, %a-border-btn {
    @extend %a-btn;
    box-shadow: none;
    border: 1px solid $base-color;
}

// 無料会員登録（トップ）
.a-btn--beginner {
    @extend %a-border-btn;

    border-color: #75af3a;

    .ico, .button-text {
        color: $cart-btn-color;
    }

    .button-text {
        font-weight: bold;
    }
}

// 予約済み（影なし枠線付き）　標準ボタン
.a-basic-btn--reserve-rev {
    @extend %a-border-btn;
    border: 1px solid $strong-blue;
    color: $strong-blue;

    &:active, &:focus, &:visited, &:link {
        color: $strong-blue;
    }

    span {
        color: $strong-blue;
    }
}

// 立体ボタン
// ==============================================
.a-3d-btn, %a-3d-btn {
    @extend %a-btn;
    border: none;
    box-shadow: 0 -3px 0 0 rgba(0,0,0,.3) inset;

    &:active, &:focus {
        text-decoration: none;
    }

    &:hover {
        box-shadow: 0 3px 0 0 rgba(0,0,0,.3) inset;
        opacity: 1;
        text-decoration: none;
    }

    .ico-txt, .button-text {
        font-weight: bold;
    }
}

// 話読みで読む
.a-3d-btn--warensai {
    @extend %a-3d-btn;
    background-color: $ttsk-blue-color;
    box-shadow: 0 -2px 0 0  rgba(0,0,0,.3) inset;
    color: #fff;
    font-size: 13px;
    padding: 10px 0;

    &:hover {
        box-shadow: 0 2px 0 0 rgba(0,0,0,.3) inset;
        opacity: 1;
    }

    span {
        color: #fff;
    }
}

// 作品詳細ページで予約
.a-3d-btn--reserve {
    @extend %a-3d-btn;
    background-color: $strong-blue;
    box-shadow: 0 -2px 0 0 #082c51 inset;
    color: #fff;
    font-size: 13px;
    padding: 10px 0;

    &:hover {
        box-shadow: 0 2px 0 0 rgba(0,0,0,.3) inset;
        opacity: 1;
    }

    span {
        color: #fff;
    }
}

.a-3d-btn--detail-reserve {
    @extend .a-3d-btn--reserve;
    font-size: 13px;
}

// 無料会員登録
.a-3d-btn--member-registration {
    @extend %a-3d-btn;
    background-color: $cart-btn-color;
    color: #fff;

    .ico, .button-text {
        color: #ffffff;
    }
}

// ボタン内アイコン微調整
// ==============================================
%a-btn-ico-adjust {
    &.ico-book::before {
        margin-top: -0.1em;
    }
    &.ico-cart::before {
        font-size: 1.2em;
        margin-top: -0.2em;
    }
    &.ico-gift::before {
        margin-top: -0.2em;
    }
    &.ico-Zero-Books::before {
        margin-top: -0.1em;
    }
    &.ico-close::before {
        margin-top: -0.1em;
    }
}

// 長丸ボタン
// ==============================================
.a-rounded-btn, %a-rounded-btn {
    @extend %a-rounded-btn;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 320px;
    width: 100%;
    height: 24px;
    margin: auto;
    border-radius: 24px;
    font-weight: bold;
    text-align: center;
    @include hover;
    &:active, &:focus {
        text-decoration: none;
    }
}

@mixin rounded-btn {
    @extend %a-rounded-btn;
}

.a-rounded-btn {
    @include rounded-btn;
}

// 右矢印アイコン付長丸ボタン(塗りつぶしあり)
%a-rounded-btn--right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 24px;
    background-color: $base-color;
    border-radius: 24px;
    font-weight: bold;
    color: #fff;
    background-color: $base-color;
    color: #fff;
    padding: 0 24px 0 16px;
    word-break: keep-all;
    @include hover;

    &::after {
        @include right-arrow($color: #fff, $right: 9px);
    }
}

.a-rounded-btn--right-arrow {
    @extend %a-rounded-btn--right-arrow;
    font-size: 10px;
}

// 右矢印アイコン付長丸ボタン(塗りつぶしなし)
%a-rounded-btn--right-arrow-border {
    @extend %a-rounded-btn--right-arrow;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;

    &::after {
        @include right-arrow($color: #fff, $right: 9px);
    }
}

.a-rounded-btn--right-arrow-border {
    @extend %a-rounded-btn--right-arrow-border;
    font-size: 10px;
}


//　長丸ボタン small
.a-rounded-btn--small,
%a-rounded-btn--small {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 13px;
    @include hover;

    .btn__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 30px;
        border-radius: 24px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        margin: 0 auto;
    }

    .ico {
        padding-left: 0.5em;
    }

    &:active, &:focus, &:visited, &:link {
        text-decoration: none;
    }

    //　ピンク
    &.a-rounded-btn--pink {
        .btn__inner {
            background-color: $menu-badge-color;
        }
    }

    //　ネイビー
    &.a-rounded-btn--navy {
        .btn__inner {
            background-color: $base-color;
        }
    }
}


// モーダルクローズボタン
// ==============================================
.a-modal-close-btn {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-align: center;
    background: #fff;
    border: 1px solid $base-color;
    background: #fff;
    color: $base-color;
    cursor: pointer;
    .ico {
        color: $base-color;
        padding: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
    }
}
.a-modal-close-btn-box {
    position: absolute;
    cursor: pointer;
    z-index: $book-item-btn-z-index;
    @include hover;
}

// 最近見た作品 削除ボタン
// ==============================================
.a-delete-btn {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-align: center;
    background: #fff;
    border: 1px solid $strong-red;
    box-sizing: border-box;
    color:  $strong-red;
    cursor: pointer;
    .ico {
        color: $strong-red;
        padding: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
    }
}

.a-delete-btn-box {
    position: absolute;
    cursor: pointer;
    z-index: $book-item-btn-z-index;
    @include hover;
}


// 折り畳みテキスト開閉ボタン用
// ==============================================
.a-text-collapse__toggle-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(0);
    top: 0;
    width: 100%;
    height: 120px;
    cursor: pointer;

    // 矢印
    &::after {
        display: block;
        content: '';
        position: absolute;
        right: 50%;
        bottom: 16px;
        width: 10px;
        height: 10px;
        border-left: 2px solid $base-color;
        border-bottom: 2px solid $base-color;
        transition: 0.2s;
    }

    &.on {
        background: linear-gradient(180deg,rgba(255,255,255,0) 45%,rgba(255,255,255,.8) 70%,#fff 85%);
        overflow: hidden;
        &::after {
            transform: translateY(-50%) rotate(315deg);
        }
    }

    &.off {
        height: 100%;
        overflow: auto;
        &::after {
            transform: translateX(-50%) rotate(135deg);
        }
    }
}