// 外部IDログインボタン

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

%a-external-id-login-btn-base {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    padding-left: 42px;
    max-width: 320px;
    background-size: 42px 42px;
    background-repeat: no-repeat;
    border-radius: 4px;
    border: 1px solid $base-color;
    font-weight: bold;
    color: #ffffff;
    overflow: hidden;
    @include hover;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-left: 1px solid transparent;
        color: #ffffff;
    }
}

.a-external-id-login-btn--nico {
    @extend %a-external-id-login-btn-base;
    background-image: url(/ex/scc/common/img/btn_icon_nico.png);
    background-color: #252525;;
    border-color: #252525;;
}

.a-external-id-login-btn--google {
    @extend %a-external-id-login-btn-base;
    background-image: url(/ex/scc/common/img/btn_icon_google.png);
    background-color: #ea4234;
    border-color: #ea4234;
}

.a-external-id-login-btn--x {
    @extend %a-external-id-login-btn-base;
    background-image: url(/ex/scc/common/img/btn_icon_x.svg);
    background-color: $x-color;
    border-color: $x-color;

    span {
        text-align: center;
    }
}
