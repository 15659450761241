// レイアウト

// ==============================================
// 読み込み
// ==============================================

@import "../../atoms/buttons/button";
@import "../../atoms/buttons/icon-button";
@import "../../atoms/arrow-mixin";
@import "../../atoms/misc";
@import "../../atoms/labels";
@import "../../atoms/check";
@import "../../atoms/tags";
@import "../../atoms/text-link";
@import "../../atoms/text";
@import "../../atoms/title";
@import "../../molecules/list";
@import "../../molecules/book-item";
@import "../../organisms/section";
@import "../../organisms/side-section";

// ==============================================
// スタイル
// ==============================================

.t-contents {
    min-width: 1050px;
    padding: 0 15px 15px 15px;

    .t-contents-inner {
        display: flex;
        justify-content: space-between;
        position: relative;

        *, *::before, *::after {
            box-sizing: border-box;
            -webkit-text-size-adjust: 100%;
        }

        .t-main-column {
            width: calc(100% - (226px + 15px));// flexの場合widthを指定しないとslick側で幅が正常に取れない
        }

        .t-side-column {
            flex-shrink: 0;
            width: 226px;
            min-height: 600px;
        }
    }
}

// Chrome系では、bodyにoverflow:hiddenつけることで、モーダルが出ている最中に
// 裏側のスクロールを禁止できる。ただし、iOS系では無効
.t-body--modal-back {
    overflow: hidden;
}