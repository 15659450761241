/* PC館TOP,総合TOPの横断バナー */
.o-campaign-banner-list {
    .o-campaign-banner {
        //背景色は管理画面で指定があると上書きされる。
        background-color: #fff;
        text-align: center;
        margin: 0 auto 10px;

        img {
            max-width: 100%;
            //height: 60px;
            vertical-align: middle;

            //@media screen and (max-width: 1080px) {
                //height: calc(100vw * ( 60/1080 ));
            //}
        }

        a {
            display: block;
        }
    }
}