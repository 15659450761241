// ランキング（サイドカラム）

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";
@import "../../molecules/card";
@import "../../atoms/tab";

// ==============================================
// スタイル
// ==============================================

.m-side-ranking {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 20px 1fr;
    grid-column-gap: 2px;
    padding-top: 4px;

    input[name=side-ranking-tab] {
        display: none;
    }

    // ラジオボタンのチェックに対応するコンテンツ部分を表示
    .m-side-ranking__tab--daily:checked ~ .m-side-ranking__contents--daily,
    .m-side-ranking__tab--weekly:checked ~ .m-side-ranking__contents--weekly,
    .m-side-ranking__tab--monthly:checked ~ .m-side-ranking__contents--monthly {
        display: block;
    }

    // コンテンツ部分
    .m-side-ranking__contents--daily,
    .m-side-ranking__contents--weekly,
    .m-side-ranking__contents--monthly {
        display: none;
        grid-column: 1/4;
        border-top: 2px solid $base-color;

        .m-card {
            border-bottom: 1px dotted $border-color;

            &:last-of-type {
                border-bottom: none;
            }

            // 情報ブロック
            // =============================================
            .m-book-item__info-block {
                margin-left: 8px;

                .m-book-item__secondary {
                    grid-template-columns: min-content auto; // ランクとジャンルを横並びに
                    grid-column-gap: 6px;
                    grid-row-gap: 3px;
                    align-items: center;
                    padding-bottom: 3px;

                    > * {
                        &:not(.m-book-item__rank):not(.m-book-item__tag-box) {
                            grid-column: 1 / 3; // 上の指定で2カラムになったものを1カラムにする
                        }
                    }

                    .m-book-item__rank,
                    .m-book-item__tag-box {
                        grid-column: unset;
                    }
                }

                // 作品タイトル
                .m-book-item__title {
                    font-size: 11px;
                }
            }
        }
    }
}