// トップ

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import "../atoms/buttons/button";

// ==============================================
// スタイル
// ==============================================

// 共通セクション（総合・館トップ等）
.o-contents-section {

    // ヘッダー
    .o-contents-section__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 12px;
        min-height: 40px;
        background-color: $base-color;

        // タイトル
        .o-contents-section__title {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            line-height: 1.3;
        }

        // 右寄せ
        .o-contents-section__header-right {
            display: flex;
        }

        // タイトル
        .o-contents-section__header-more {
            align-self: center;
            @extend %a-rounded-btn--right-arrow-border;
            font-size: 12px;
        }

        .o-contents-section__powered {
            font-size: 9px;
            color: #ffffff;

            > a {
                margin-left: 3px;

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .o-contents-section__search_count {
            font-size: 13px;
            color: #ffffff;
        }
    }

    // ボディ（コンテンツ部分）
    .o-contents-section__body {
        margin-top: 12px;
    }

    // もっと見る
    .o-contents-section__more {
        padding: 24px 0 12px;

        .a-btn {
            min-width: 400px;
        }
    }

    &.r18 {
        .o-contents-section__header {
            background-color: $r18-key-color;
        }
    }
}
