// その他のジャンル（総合トップ：リンクリスト）

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-other-genre-link-list {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px;

    * {
        box-sizing: border-box;
    }

    li {
        flex: 1;
        margin: 0 5px;
        min-height: 86px;
        max-height: 100px;
        height: 7vw;
        border: 1px solid #cccccc;
        border-radius: 4px;
        z-index: 1;

        &:last-of-type {
            margin-bottom: 0;
        }

        // BL
        &.m-other-genre-link-list__item--bl {
            a {
                border-color: #ff6ed8;
                background-color: #ffc5dd;
                color: #a11c6f;

                &::before {
                    background-color: #ff98dc;
                }

                &::after {
                    border-color: #a11c6f;
                }
            }
        }

        // TL
        &.m-other-genre-link-list__item--tl {
            a {
                border-color: #b481d8;
                background-color: #e8caff;
                color: #65349c;

                &::before {
                    background-color: #c793f0;
                }

                &::after {
                    border-color: #65349c;
                }
            }
        }

        // 同人誌・個人出版
        &.m-other-genre-link-list__item--indies {
            a {
                border-color: #5ab5bc;
                background-color: #b3ecf0;
                color: #145470;

                &::before {
                    background-color: #81d9df;
                }

                &::after {
                    border-color: #145470;
                }
            }
        }

        a {
            display: flex;
            align-content: center;
            align-items: center;
            position: relative;
            padding-right: 28px;
            height: 100%;
            border-left: 12px solid #cccccc;
            text-decoration: none;
            overflow: visible;

            &:hover {
                opacity: 0.7;
            }

            // 左の装飾の上半分
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: -12px;
                top: 0;
                width: 12px;
                height: 50%;
                background-color: #ffffff;
            }

            // 右の矢印
            &::after {
                content: "";
                display: block;
                @include centering(y, 45);
                right: 16px;
                width: 10px;
                height: 10px;
                border-top: 2px solid #ffffff;
                border-right: 2px solid #ffffff;
            }

            .m-other-genre-link-list__item-cover {
                flex-shrink: 0;
                margin-left: 12px;
                margin-right: 12px;
                padding: 10px 0;
                height: 100%;
                font-size: 0;

                img {
                    max-height: 100%;

                    &:first-of-type {
                        margin-right: 6px;
                    }
                }
            }

            .m-other-genre-link-list__item-txt {
                font-size: 20px;
                font-weight: bold;
                line-height: 1;

                small {
                    font-size: 0.8em;
                    font-weight: normal;
                }
            }
        }
    }
}