// 白い×ボタン（主にモーダル向け）

@import "../../templates/layout/mixin";

.a-large-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    @include opacity-hover;

    .ico-close {
        color: #fff;
        font-size: 30px;
    }
}
