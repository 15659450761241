// タイル

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-tile {
    .m-book-item {
        @extend %m-book-item;

        &.top {
            .m-book-item__info-block {
                justify-content: unset;
            }
        }
    }

    .m-thumb {
        .m-thumb__check {
            right: 5px;
        }
    }
}
