@import "colors";

@mixin right-arrow($color: $arrow-color) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 8px;
    height: 8px;
    border-right: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(-45deg);
}

@mixin up-arrow($color: $arrow-color) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    border-left: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(135deg);
    transition: .2s;
}

@mixin down-arrow($color: $arrow-color) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    border-left: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(315deg);
    transition: .2s;
}
