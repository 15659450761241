// タブ

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.a-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    border: 1px solid #cccccc;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    background-color: #ffffff;
    padding-top: 1px;
    font-weight: bold;

    &:hover {
        @extend %a-tab-active;
    }
}

%a-tab-active {
    background-color: $base-color;
    color: #ffffff;
}

input:checked + .a-tab {
    @extend %a-tab-active;
}
