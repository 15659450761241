// いまいち分類が難しいものを置く。
// あんまり増やさないこと

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

span, p{
    &.nowrap {
        white-space: nowrap;
    }
}