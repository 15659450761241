// ジャンル選択モーダル（レスポンシブ）

// ==============================================
// 読み込み
// ==============================================
@import "../templates/layout/mixin";
@import "../templates/layout/font-variable";
@import "../atoms/colors";

@import "../atoms/misc";
@import "../atoms/buttons/commons";
@import "../molecules/outside-button-modal";
@import "../molecules/keyword-list";

#react-personalise-modal {
    // 既存のスタイルの上書き
    .a-overlay {
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;
        opacity: 0;

        &.on {
            visibility: visible;
            opacity: 1;
        }
    }

    .m-personalize-modal__wrap {
        @include outside-button-modal__wrap;
        display: none;

        &.on {
            display: flex;
        }
    }

    .m-personalize-modal {
        @include outside-button-modal;
    }

    .m-personalize-modal__base {
        @include outside-button-modal__base;

        // 既存スタイルの上書き
        .a-large-close-btn {
            position: absolute;
            top: 0;
            right: 16px;

            // louisのreset.scssにbuttonタグの打消しが入っていない対策
            border: none;
            outline: 0;
            background-color: transparent;
            appearance: none;
        }
    }

    .m-personalize-modal__body {
        @include outside-button-modal__body(24px, 16px, 16px, 16px);

        @media screen and (min-width: 380px) {
            padding: 24px 24px 16px;
        }

        .m-personalize-modal__title {
            color: $base-color;
            font-size: 16px;
            font-weight: $font-bold;
            text-align: center;

            span {
                display: inline-block;
            }

            .text-pink {
                color: $menu-badge-color;
            }
        }

        .m-personalize-modal__caption {
            color: $base-text-color;
            font-size: 12px;
            text-align: center;
            margin-top: 8px;

            @media screen and (max-width: 330px) {
                margin: 8px -14px 0;
            }
        }

        // 既存スタイルの上書き
        .m-keyword-list--check {
            margin-top: 8px;

            @media  screen and (max-width: 360px){
                margin: 8px -12px;
            }
            @media screen and (max-width: 330px) {
                margin: 8px -16px;
            }

            li button {
                // louisのreset.scssにbuttonタグの打消しが入っていない対策
                background-color: transparent;
                outline: 0;
                appearance: none;
            }
        }

        // 既存スタイルの上書き
        .a-rounded-btn--disable,
        .a-rounded-btn--navy {
            margin-top: 20px;

            // louisのreset.scssにbuttonタグの打消しが入っていない対策
            border: none;
            outline: 0;
            appearance: none;

            +.m-personalize-modal__cancel {
                margin-top: 8px;
            }
        }

        .m-personalize-modal__cancel {
            width: 100%;
            color: $base-link-color;
            font-size: 12px;
            text-align: center;
            padding: 8px;
            @include opacity-hover;

            // louisのreset.scssにbuttonタグの打消しが入っていない対策
            background-color: transparent;
            border: none;
            outline: 0;
            appearance: none;
        }
    }
}
