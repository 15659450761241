// 右カラム：話題の電子書籍・ランキング

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";
@import "../card";

// ==============================================
// スタイル
// ==============================================

.m-side-topic-book {
    .m-card {
        border-bottom: 1px dotted $border-color;

        &:last-of-type {
            border-bottom: none;
        }

        // 情報ブロック
        // =============================================
        .m-book-item__info-block {
            margin-left: 8px;

            .m-book-item__secondary {
                grid-row-gap: 3px;
                padding-bottom: 3px;
            }

            // 作品タイトル
            .m-book-item__title {
                font-size: 11px;
            }
        }
    }
}