// バナーリスト

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-side-banner-list {
    li {
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        a {
            &:hover {
                opacity: 0.6;
            }
        }
    }
}