// 総合トップ

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";
@import "../../templates/layout/layout";
@import "../../atoms/buttons/external-id-login-button";
@import "../../molecules/tile-list";
@import "../../molecules/banner-list";
@import "../../molecules/other-genre-link-list";
@import "../../molecules/side-column/side-external-id-login";
@import "../../molecules/side-column/side-beginner";
@import "../../molecules/side-column/side-follow-list";
@import "../../molecules/side-column/side-topic-book";
@import "../../molecules/side-column/side-ranking";
@import "../../molecules/side-column/side-link-list";
@import "../../molecules/side-column/side-banner-list";
@import "../../molecules/side-column/side-original-service";
@import "../../molecules/side-column/side-sns";
@import "../../../sp/molecules/reserve-modal";
@import "../../organisms/beginner-btn-block";
@import "../../organisms/store-top-banner";
@import "../../organisms/beginner-banner";
@import "../../organisms/campaign-banner";

// レスポンシブのパーツなのでSPから読み込み
@import "../../../sp/molecules/personalise-modal";

// ==============================================
// スタイル
// ==============================================

//上部バナー領域
.p-campaign-banner-list-wrap {
    margin: 0 -15px;
}

.p-beginner-banner-wrap {
    margin: 0 -15px;
}

// メインカラム
.t-main-column {
    padding-bottom: 15px;
}

// ＼ まずはここから ／
.o-beginner-btn-block {
    margin-top: 24px;

    +.o-contents-section {
        margin-top: 48px;
    }
}

// 各コンテンツセクション
.o-contents-section {
    margin-top: 24px;

    &:first-of-type {
        margin-top: 0;
    }

    // バナーリスト
    .m-banner-list {
        margin-bottom: 48px;
    }
}

// MA（埋め込み）
.o-ma-embed {
    margin-top: 24px;
}

