// ==============================================
// トップスライドバナー
// ==============================================

// 読み込み
@import "../molecules/swiper";

//館カラー対応用変数
$store-key-colors: (
    sougou: $base-color,
    shinjuku: $manga-key-color,
    yaesu: $literature-business-key-color,
    akihabara: $light-novel-key-color,
    newuser: $indies-key-color,
    ikebukuro: $bl-key-color,
    ikebukuro-tl: $tl-key-color,
    otona: $r18-key-color
);

// 他世代にある要素の調整
#intro {
    background: none;

    //パンくずがすぐ下にくる場合の余白調整
    + #pageWrap , + .t-contents {
        .bw_link-breadcrumb {
            margin-top: 0;
        }
    }
}

// hoverを館カラーで上書き（カルーセル、非カルーセル共通）
@each $key, $color in $store-key-colors {
    ##{$key} {
        .swiper-slide a:hover::after {
            border: 2px solid $color;
        }

        .swiper-pagination-bullet:hover::before {
            background-color: $color;
        }

        .swiper-pagination-bullet-active::before {
            background-color: $color;
        }

        //スライド2枚以下の場合
        .o-store-top-banner__item:hover::after {
            border: 2px solid $color;
        }
    }
}

// カルーセル
.o-store-top-banner {
    position: relative;
}

//カルーセルなし（バナー2枚以下）の場合
.o-store-top-banner--no-slide {
    padding: 0 15px;
    margin-bottom: 10px;

    .o-store-top-banner__inner {
        @include banner-wrap;
    }

    .o-store-top-banner__item {
        @include banner;
    }
}


@import "louis/pc/scss/inline-css-1";

// 無料漫画バナー
.sideFreemangaBannerWrap {
    margin: 0 0 10px 2px;
}

// 使用箇所不明のため、不要だと発覚したら消してください
.topjack-wrap {
    margin: 3px 0 3px;
    text-align: center;

    img {
        max-width: 100%;
        width: auto;
        border: none;
    }
}
