// テキスト装飾

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// テキスト装飾
%text-base {
    &.strong {
        color: $strong-red;
    }

    &.bold {
        font-weight: bold;
    }

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }

    &.underline {
        text-decoration: underline;
    }


    &.nowrap {
        white-space: nowrap;
    }

    &.line-through {
        text-decoration: line-through;
    }

    &.normal {
        font-weight: normal;
    }
}

p, span, a {
    @extend %text-base;
}