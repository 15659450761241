// テキストリンク

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// リンク
.a-small-text-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding-right: 12px;
    font-size: 12px;

    &::after {
        content: "";
        display: block;
        width: 0.6em;
        height: 0.6em;
        border-top: 2px solid $base-color;
        border-right: 2px solid $base-color;
        @include centering(y, 45);
        right: 2px;
    }
}

// ?アイコンリンク
.a-help-link {
    color: $base-link-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    @include hover;

    .ico {
        position: relative;
        padding: 0 0 0 4px;
        margin-top: -0.1em;
        font-size: 14px;
    }

    &:active,
    &:focus,
    &:hover {
        color: $base-link-color;
        text-decoration: none;
    }

    &:hover {
        .a-help-link__text {
            text-decoration: none;
        }
    }
}
