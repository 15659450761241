// チェックボックス（マーク）

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import '../../sp/atoms/_z-index.scss';

// ==============================================
// スタイル
// ==============================================

.a-check {
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background-image: url(/anne/img/common/btn-check-off.svg);
    background-size: 22px 22px;
    font-size: 0;
    text-indent: 100%;
    white-space: nowrap;
    z-index: $label-z-index;
}

.a-check--on {
    display: block;
    width: 22px;
    height: 22px;
    overflow: hidden;
    background-image: url(/anne/img/common/btn-check-on.svg);
    background-size: 22px 22px;
    font-size: 0;
    text-indent: 100%;
    white-space: nowrap;
    z-index: $label-z-index;
}
