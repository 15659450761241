//新規ユーザー向け半額還元バナー
.o-beginner-banner {
    text-align: center;
    margin: 0 auto 10px;

    img {
        max-width: 100%;
        //height: 100px;
        vertical-align: middle;

        //@media screen and (max-width: 1080px) {
            //height: calc(100vw * ( 60/1080 ));
        //}
    }

    a {
        display: block;
    }
}
