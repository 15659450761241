@import "../colors";
@import "../../templates/layout/font-variable";
@import "../../templates/layout/mixin";
@import "./button-base-mixin";

// ストア内で使用する読み放題用ボタン
// ==============================================
// 塗りつぶし（継承専用）
%a-sbsc-button-filled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    text-decoration: none;

    .button-text {
        color: inherit;
        line-height: 1;
    }
}

// アウトライン（継承専用）
%a-sbsc-button-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    text-decoration: none;

    .button-text {
        color: inherit;
        line-height: 1;
    }
}

// 読むボタン（アイコン付）
.a-sbsc-icon-read-button {
    @extend %a-sbsc-button-filled;
    flex-direction: column;
    background-color: $sbsc-red;
    border-color: $sbsc-red;
    color: #fff;

    .ico-book-open {
        display: block;
        font-size: 18px;
    }

    .button-text {
        display: block;
        color: #fff;
        font-weight: $font-bold;
        margin-top: 4px;
    }

    @include hover-pc {
        opacity: 1;
        background-color: $sbsc-dark-red;
        border-color: $sbsc-dark-red;
    }
}

// お気に入りボタン（アイコン付）
.a-sbsc-icon-favorite-button {
    @extend %a-sbsc-button-outline;
    flex-direction: column;
    background-color: #fff;
    border-color: $sbsc-blue;
    color: $sbsc-blue;
    font-size: 10px;

    .ico-plus {
        font-size: 18px;
    }

    .button-text {
        font-weight: $font-bold;
        margin-top: 4px;
    }

    .button-omit-text {
        @media screen and (max-width: 899px) {
            display: none;
        }
    }

    @include hover-pc {
        opacity: 1;
        background-color: $sbsc-pale-blue;
        border-color: $sbsc-blue;
    }
}

// お気に入りボタン追加済み（アイコン付）
.a-sbsc-icon-favorite-button--on {
    @extend %a-sbsc-button-filled;
    flex-direction: column;
    background-color: $sbsc-pale-blue;
    border-color: $sbsc-pale-blue;
    color: $sbsc-blue;

    .ico-plus {
        font-size: 16px;
        opacity: 0.3;
    }

    .button-text {
        font-weight: $font-bold;
        margin-top: 4px;
    }

    @include hover-pc {
        opacity: 1;
        background-color: #d6e7ff;
        border-color: #d6e7ff;
    }
}
