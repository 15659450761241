// 見出し

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// 背景あり見出し
.a-common__title--bg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 24px;
    min-height: 40px;
    background-color: $base-color;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
}