/**
  いまいち分類が難しいものを置く。
  あんまり増やさないこと
 */
@import "colors";
@import "../atoms/z-index";

// ローディング中の部品、ブックウォーカーのロゴを中に出す
#a-autoLoading {
    text-align: center;
    margin: 0 0 21px;
}

.a-not-found-text {
    text-align: center;
    color: $strong-red;
    font-weight: bold;
}

// 閲覧履歴数（検索結果数）
.a-result-wrap {
    display: block;
    padding: 4px 8px;
    border-bottom: 1px solid $border-color;
    font-size: 10px;
    color: $base-text-color;
    width: 100%;
}

// 背景オーバーレイ
.a-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: $overlay-z-index;
}

// 背景オーバーレイ（透明）
.a-overlay--transparent {
    @extend .a-overlay;
    background-color: rgba(0, 0, 0, 0);
}