// 無料会員登録・はじめての方へ

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-side-beginner {
    .a-btn--beginner {
        margin-top: 10px;
    }
}