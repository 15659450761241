// 矢印

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

@mixin right-arrow($color: $arrow-color, $right: 15px, $size: 8px) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: $right;
    width: $size;
    height: $size;
    border-right: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(-45deg);
}

@mixin up-arrow($color: $arrow-color, $right: 20px, $size: 10px) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: $right;
    width: $size;
    height: $size;
    border-left: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(135deg);
    transition: .2s;
}

@mixin down-arrow($color: $arrow-color, $right: 20px, $size: 10px) {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    right: $right;
    width: $size;
    height: $size;
    border-left: 2px solid $color;
    border-bottom: 2px solid $color;
    transform: translateY(-50%) rotate(315deg);
    transition: .2s;
}
