// 予約購入ボタンをクリックで表示するモーダル

// ==============================================
// 読み込み
// ==============================================
@import "../templates/layout/font-variable";
@import "../templates/layout/mixin";
@import "../../pc/atoms/buttons/button";
@import "../atoms/colors";
@import "../atoms/z-index";
// ==============================================
// スタイル
// ==============================================
.m-reserve-modal {
    display: none;
    align-items: flex-end;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    height: 100%;
    bottom: 0;
    box-sizing: border-box;
    color: $base-text-color;
    padding: 0 20px;
    pointer-events: none;
    z-index: $reserve-modal-z-index;

    @include pc {
        justify-content: flex-start;
    }

    .m-reserve-modal__base {
        position: relative;
        max-width: 400px;
        max-height: calc(100% - 40px);
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        margin-bottom: 20px;
        overflow-y: auto;
        padding: 0 16px 16px;
        pointer-events: auto;

        * {
            box-sizing: border-box; // louisやbobで読み込むため
        }

        // 読了後画面でスタイルが崩れるため
        p, dl {
            font-size: 12px;
            line-height: 1.3;
            margin: 0;
            text-align: left;
        }

        // BLトップSPで崩れるため
        a {
            display: inline;
        }
    }

    .m-reserve-modal__header {
        padding: 16px 0;
    }

    .m-reserve-modal__header-title {
        font-size: 16px;
        font-weight: $font-bold;
        line-height: 1.5;
    }

    .m-reserve-modal__header-text {
        font-size: 12px;
        line-height: 1.3;
        margin-top: 4px;

        .m-reserve-modal__header-text-link {
            color: $base-link-color;
            font-weight: $font-bold;
            text-decoration: underline;

            @include hover-pc {
                text-decoration: none;
            }
        }
    }

    .m-reserve-modal__box {
        display: grid;
        height: 230px;
        border: 1px solid $border-color;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 1.3;
        overflow-y: auto;
        padding: 16px;
        grid-gap: 16px;

        @include utab {
            height: 90px;
        }

        .m-reserve-modal__box-title {
            font-size: 12px;
            font-weight: $font-bold;
            line-height: 1.3;
        }
    }

    .m-reserve-modal__box-info {
        font-size: 12px;
        line-height: 1.3;
        word-break: break-all;

        dt {
            float: left;
            font-weight: $font-bold;
        }

        dt, dd {
            &:not(:first-of-type) {
                margin-top: 16px;
            }
        }
    }

    .m-reserve-modal__box-item {
        .m-reserve-modal__box-item-title {
            font-size: 12px;
            font-weight: $font-bold;
            line-height: 1.3;
            margin-bottom: 4px;
        }

        .m-reserve-modal__box-item__link {
            color: $base-link-color;
            font-size: 12px;
            font-weight: $font-bold;
            line-height: 1.3;
            text-decoration: underline;

            @include hover-pc {
                text-decoration: none;
            }
        }
    }

    .a-modal-close-btn-box {
        top: 0;
        right: 0;
        padding: 8px 8px 18px 18px;
    }
}