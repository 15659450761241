// カード

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-card {
    .m-book-item {
        @extend %m-book-item;
        flex-direction: row;

        // 書影ブロック
        // ============================================
        .m-book-item__thumb-block {
            width: 64px;
            max-height: 92px;
            flex-shrink: 0;

            // サムネイル
            .m-thumb {
                width: 100%;

                // ラベルエリア
                .m-thumb__label-area {
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                }

                // チェックボタン
                .m-thumb__check {
                    right: -8px;
                    bottom: -8px;
                    width: 34px;
                    height: 34px;
                }

                .m-thumb__image {
                    img {
                        max-height: 92px;
                    }
                }
            }
        }

        // 情報ブロック
        // =============================================
        .m-book-item__info-block {
            margin-top: 0;
            margin-left: 10px;

            // ジャンルタグ
            .m-book-item__tag-box {
                display: flex;
                flex-wrap: wrap;
                margin-top: -2px;
                font-size: 0;

                // タグ本体
                span {
                    margin: 2px 4px 0 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

    }
}
