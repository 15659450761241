// フォローリスト（右カラム）

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-side-follow-list {
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 5px 3px 6px;
        min-height: 36px;
        border-bottom: 1px dotted $border-color;

        &:last-of-type {
            border-bottom: none;
        }

        .m-side-follow-list__name {
            display: -webkit-box;
            font-size: 12px;
            line-height: 1.1;
            max-height: 1em;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: normal;
            overflow: hidden;
            text-overflow: clip;
            word-break: break-all;

            // iOSでline-clampの表示対策（何かで囲っていないと3点リーダが中途半端な位置に出る）
            :first-child {
                &::before {
                    content: ""
                }

                &::after {
                    content: "";
                }
            }
        }

        .m-side-follow-list__tag {
            display: grid;
            grid-template-columns: repeat(2, max-content);
            grid-gap: 2px;
            margin-left: 5px;
            min-width: 90px;
            font-size: 0;
        }
    }
}