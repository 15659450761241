// ×ボタンがbodyの外にあるモーダル（別途ボタンを付けるタイプ）
// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/mixin";
@import "../atoms/buttons/large-close-btn";
@import "../atoms/z-index";

// ==============================================
// スタイル
// ==============================================

// 角丸白背景のベーシックなモーダル
// ==============================================
@mixin outside-button-modal__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: $modal-z-index;
}

@mixin outside-button-modal {
    display: flex;
    max-height: 90%;
}

@mixin outside-button-modal__base {
    display: flex;
    position: relative;
    padding: 44px 16px 0;
}

@mixin outside-button-modal__body($padding-top: 24px, $padding-right: 16px, $padding-bottom: 24px, $padding-left: 16px) {
    border-radius: 4px;
    background-color: #fff;
    overflow-y: auto;
    max-width: 400px;
    padding: $padding-top $padding-right $padding-bottom $padding-left;
}
