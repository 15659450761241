// ミックスイン

// ==============================================
// スタイル
// ==============================================

// 中央揃え
// x or y or xy
@mixin centering($mode, $rotate: null, $position: absolute) {
    position: $position;
    $_rotate: null;

    @if $rotate != null {
        $_rotate :rotate($rotate + deg);
    }

    @if $mode == x {
        left: 50%;
        -ms-transform: translateX(-50%) $_rotate;
        -webkit-transform: translateX(-50%) $_rotate;
        transform: translateX(-50%) $_rotate;
    }
    @if $mode == y {
        top: 50%;
        -ms-transform: translateY(-50%) $_rotate;
        -webkit-transform: translateY(-50%) $_rotate;
        transform: translateY(-50%) $_rotate;
    }
    @if $mode == xy {
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%) $_rotate;
        -webkit-transform: translate(-50%, - 50%) $_rotate;
        transform: translate(-50%, -50%) $_rotate;
    }
}

// hover
@mixin hover {
    &:active, &:focus, &:visited {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
        @media screen and (min-width: 768px) {
            opacity:0.7
        }
    }
}

// text hover
@mixin textHover {
    &:active, &:focus, &:visited {
        text-decoration: none;
    }
    &:hover {
        @media screen and (min-width: 768px) {
            opacity: 1;
            text-decoration: underline;
        }
    }
}

// 文章の行数を制限して三点リーダーを表示するmixin
@mixin line-clamp($count: 3) {
    // 引数が数値以外だったらエラーを返す
    @if type-of($count) != 'number' {
        @error 'line-clampの引数は必ず数値で指定してください';
    }

    @if $count == 1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        display: -webkit-box;
        -webkit-line-clamp: $count;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}