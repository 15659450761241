// オリジナルサービス

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-side-original-service {
    // バナー
    .m-side-original-service__banner-list {
        li:not(:last-of-type){
            margin-bottom: 10px;
        }

        a {
            &:hover {
                opacity: 0.6;
            }
        }
    }

    // 各サービスリスト
    .m-side-original-service__service {
        display: grid;
        row-gap: 10px;
        margin-top: 12px;

        li {
            display: grid;
            grid-template-columns: 64px auto;
            column-gap: 8px;

            .m-side-original-service__service-image {
                a {
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

            .m-side-original-service__service-info {
                p {
                    &:nth-of-type(1) {
                        font-size: 12px;
                        font-weight: bold;
                    }

                    &:nth-of-type(2) {
                        margin-top: 5px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}