// アイコンボタン

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// アイコンボタン（ベース）
.a-icon-btn, %a-icon-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 65px;
    height: 40px;
    padding: 2px;
    border-radius: 4px;
    border: 1px solid $border-color;
    color: $base-color;
    background-color: #ffffff;
    box-sizing: border-box;
    @include hover;

    .ico {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
        font-size: 18px;
        margin-top: -2px;

        // アイコンの微調整
        @extend %ico-adjust;
    }

    .ico-txt, .button-text {
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
        white-space: nowrap;
    }
}

// activeまたはonのボタン
.a-icon-btn--on {
    @extend %a-icon-btn;

    .ico {
        color: $active-color;
    }

    .button-text {
        color: $active-color;
    }
}

// 背景色付きアイコンボタン（ベース）
.a-color-icon-btn, %a-color-icon-btn{
    @extend %a-icon-btn;
    padding: 3px;
    border: none;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.4) inset;

    &, &:link, &:visited {
        color: #ffffff;
    }
}

// 試し読み
.a-icon-btn--trial {
    @extend %a-icon-btn;
    border: 1px solid $base-color;
}

// 試し読み
.a-icon-btn--read {
    @extend %a-icon-btn;
    border: 1px solid $base-color;
}

// カート
.a-icon-btn--cart {
    @extend %a-color-icon-btn;
    background-color: $cart-btn-color;
}

// カートを見る
.a-icon-btn--check-cart {
    @extend %a-icon-btn;
    border: 1px solid $cart-btn-color;

    &, &:link, &:visited {
        color: $cart-btn-color;
    }
}

// 予約
.a-icon-btn--reserve {
    @extend %a-color-icon-btn;
    background-color: $strong-blue;
}

// 予約済み
.a-icon-btn--reserved {
    @extend %a-icon-btn;
    border: 1px solid $strong-blue;

    &, &:link, &:visited {
        color: $strong-blue;
    }
}

// 無料でもらう
.a-icon-btn--free {
    @extend %a-color-icon-btn;
    background-color: $free-key-color;
}

// まる読み
.a-icon-btn--maruyomi {
    @extend %a-color-icon-btn;
    padding-left: 0;
    padding-right: 0;
    background-color: $maruyomi-btn-color;
}

// 話連載
.a-icon-btn--warensai {
    @extend %a-color-icon-btn;
    background-color: $ttsk-blue-color;
    color: #fff;
}

// テキストボタン（アイコン無し）
.a-icon-btn--text {
    @extend %a-icon-btn;
    border: 1px solid $base-color;

    .ico {
        font-size: 14px;
        font-weight: bold;
    }
}

// 削除
.a-icon-btn--delete {
    @extend %a-icon-btn;
    border: 1px solid $border-color;
    color: $strong-red;
    box-shadow: 0 1px 0 #999;

    .ico {
        color: $strong-red;
        font-size: 20px;
    }
    .button-text {
        color: $strong-red;
    }
}

// ==============================================
// ボタン内アイコン微調整
// ==============================================

%ico-adjust {
    &.ico-cart::before {
        font-size: 1.2em;
        margin-top: -0.15em;
    }

    &.ico-gift::before {
        margin-top: -0.35em;
    }

    &.ico-alerm::before {
        font-size: 0.9em;
    }
}