// リスト

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import "../atoms/text";

// ==============================================
// スタイル
// ==============================================

// 標準リスト
// ==============================================
.m-text-list, %m-text-list {
    text-align: left;

    ul {
        padding-left: 1em;
    }

    li {
        font-size: 12px;
        list-style-type: none;
        line-height: 1.5;
        overflow: hidden;

        &:not(:last-of-type) {
            margin-bottom: 0.5em;
        }

        &.margin {
            margin-top: 1em;
        }

        @extend %text-base;
    }

    .m-text-list__parent {
        display: block;
        margin-bottom:0.5em;
    }

    // ・付き
    .m-text-list__item--bullet {
        padding-left: 1em;
        text-indent: -1em;

        &::before {
            content: "・";
        }
    }

    // ※付き
    .m-text-list__item--annotation {
        padding-left: 1em;
        text-indent: -1em;

        &::before {
            content: "※";
        }
    }
}
