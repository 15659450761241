// ラベル

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import '../../sp/atoms/_z-index.scss';

// ==============================================
// スタイル
// ==============================================

@mixin label-base {
    position: absolute;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 48px 45px;
    width: 48px;
    height: 45px;
}

// 読み放題
.a-label-subscription {
    @include label-base;
    background-image: url(/anne/img/common/icon-sbsc.svg);
    background-size: 48px 20px;
    width: 48px;
    height: 20px;
    top: 0;
    left: 0;
    z-index: $label-z-index;
}

// \0（無料）
.a-label-free {
    @include label-base;
    background-image: url(/anne/img/common/icon/icon-free.svg);
    top: 0;
    left: 0;
    z-index: $label-sale-free-z-index;
}

// SALE
.a-label-sale {
    @include label-base;
    background-image: url(/anne/img/common/icon/icon-sale.svg);
    top: 0;
    left: 0;
    z-index: $label-sale-free-z-index;
}

%a-label-base {
    @include label-base;
    top: 0;
    right: 0;
    z-index: $label-z-index;
}

// NEWのリボン
.a-label-new {
    @extend %a-label-base;
    background-image: url(/anne/img/common/label/label-new.svg);
}

// 予約リボン
.a-label-reserve {
    @extend %a-label-base;
    background-image: url(/anne/img/common/label/label-reserve.svg);
}

// セット商品用リボン
.a-label-set {
    @extend %a-label-base;
    background-image: url(/anne/img/common/label/label-set.svg);
}

// 試し読み増アイコン
.a-label-inc-trial {
    @include label-base;
    background-image: url(/anne/img/common/label/label-inc-trial.svg);
    top: 0;
    right: 0;
    z-index: $label-trial-reading-z-index;
}

// コインアップ帯
%a-label-coin-up {
    @include label-base;
    width: 24px;
    height: 66px;
    left: 0;
    bottom: 0;
    z-index: $label-z-index;
}

// コインアップ帯1・2・3
.a-label-coin-up-1 {
    @extend %a-label-coin-up;
    background-image: url(/anne/img/common/label/label-coinup1.svg);
    background-size: 24px 66px;
    height: 66px;
}

.a-label-coin-up-2 {
    @extend %a-label-coin-up;
    background-image: url(/anne/img/common/label/label-coinup2.svg);
    background-size: 24px 82px;
    height: 82px;
}

.a-label-coin-up-3 {
    @extend %a-label-coin-up;
    background-image: url(/anne/img/common/label/label-coinup3.svg);
    background-size: 24px 98px;
    height: 98px;
}

// ランキング順位---------------
@mixin ranking-mark {
    font-size: 10px;
    font-weight: 700;
    .ico {
        font-size: 10px;
        padding-right: 2px;
    }
    .rank-txt-s {
        font-size: 10px;
    }
}

// 1位
.a-rank-first {
    @include ranking-mark;
    color: $first-rank-color;
}

// 2位
.a-rank-second {
    @include ranking-mark;
    color: $second-rank-color;
}

// 3位
.a-rank-third {
    @include ranking-mark;
    color: $third-rank-color;
}

// 4位以下
.a-rank-other {
    @include ranking-mark;
    color: $other-rank-color;
}

// R18 ADULT ONLYのアイコン
.a-label-adult-only {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    background: url("/anne/img/common/r18-mark.svg") no-repeat;
    background-size: 50px 50px;
}