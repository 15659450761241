// はじめての方へ・無料会員登録

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import "../atoms/buttons/button";

// ==============================================
// スタイル
// ==============================================

.o-beginner-btn-block {
    padding: 30px;
    background-color: #ffffff;

    .o-beginner-btn-block__title {
        font-weight: bold;
        text-align: center;
    }

    .o-beginner-btn-block__btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        max-width: 960px;
        margin: 10px auto 0;
    }

    // はじめての方へボタン既存スタイル上書き
    .a-btn--beginner {
       max-width: 100%;
    }

    // 無料会員登録ボタン既存スタイル上書き
    .a-basic-btn--member-registration {
        max-width: 100%;

        .ico-txt {
            font-weight: $font-bold;
        }
    }
}
