@charset "UTF-8";

// SP Media query Mixin
// iPhone5以下
$se: 320px;
// SP
$sp: 480px;
// タブレット
$tab: 768px;
// PC
$pc: 1024px;

// PC以上の時
@mixin opc {
    @media (min-width: ($pc + 1)) {
        @content;
    }
}

// PCの時
@mixin pc {
    @media (min-width: ($tab + 1)) {
        @content;
    }
}

// タブレット以下の時
@mixin utab {
    @media (max-width: ($tab)) {
        @content;
    }
}

// スマホ以下の時
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}

// iPhoneSE以下
@mixin se {
    @media (max-width: ($se)) {
        @content;
    }
}

// タブレット以上(幅が480px以上の時)
@mixin tab {
    @media (min-width: ($sp)) {
        @content;
    }
}

// 中央揃え
// x or y or xy
@mixin centering($mode, $rotate: 0, $position: absolute) {
    position: $position;

    @if $mode == x {
        left: 50%;
        -ms-transform: translateX(-50%) rotate($rotate + deg);
        -webkit-transform: translateX(-50%) rotate($rotate + deg);
        transform: translateX(-50%) rotate($rotate + deg);
    }
    @if $mode == y {
        top: 50%;
        -ms-transform: translateY(-50%) rotate($rotate + deg);
        -webkit-transform: translateY(-50%) rotate($rotate + deg);
        transform: translateY(-50%) rotate($rotate + deg);
    }
    @if $mode == xy {
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%) rotate($rotate + deg);
        -webkit-transform: translate(-50%, - 50%) rotate($rotate + deg);
        transform: translate(-50%, -50%) rotate($rotate + deg);
    }
}

// hover
@mixin hover-pc {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

// 書影等のHover（opacity 0.7）
@mixin opacity-hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 0.7;
        }
    }
}

// テキストリンクのHover（underline）
@mixin text-hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
            text-decoration: underline;
        }
    }
}

// 文章の行数を制限して三点リーダーを表示するmixin
@mixin line-clamp($count: 3) {
    // 引数が数値以外だったらエラーを返す
    @if type-of($count) != 'number' {
        @error 'line-clampの引数は必ず数値で指定してください';
    }

    @if $count == 1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        display: -webkit-box;
        -webkit-line-clamp: $count;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}