// 外部IDでログイン

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-external-id-login {
    font-size: 14px;

    .m-external-id-login__btn-list {
        li {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .a-small-text-link {
        margin-top: 12px;
    }

    .m-external-id-login__note {
        margin-top: 12px;
        padding: 8px;
        border: 1px solid #eef0f2;
    }
}