// タグ
// ジャンル・商品情報・登録状況など

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

// タグ
// ジャンル・商品情報・登録状況など
// ==============================================
// 特典・セット・完結
%tags-base {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 14px;
    border: 1px solid transparent;
    font-size: 10px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    background: #ffffff;
    box-sizing: border-box;
    overflow: hidden;
}

// フリーサイズ（電子書籍・試読増量）
%tags-base--free {
    @extend %tags-base;
    width: auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

// ジャンル分類タグ用（角丸）
%genre-tags-base {
    @extend %tags-base;
    border-radius: 4px;
    border-color: transparent;
}

// マンガ作品タグ
.a-tag-comic {
    @extend %genre-tags-base;
    background: $manga-key-color;
}

// ライトノベル作品タグ
.a-tag-LN {
    @extend %genre-tags-base;
    background: $light-novel-key-color;
}

// R18作品タグ
.a-tag-r18 {
    @extend %genre-tags-base;
    background: $r18-key-color;
}

// 文芸作品タグ
.a-tag-literature {
    @extend %tags-base;
    width: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// 話連載のタグ
.a-tag-warensai {
    @extend %tags-base;
    width: auto;
    min-width: 48px;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// マンガ・ラノベ・文芸以外のタグ
// 文芸と同じデザイン。一応分ける
// auto width, do not fix tag text length
.a-tag-other {
    @extend %tags-base;
    width: auto;
    min-width: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border-color: $weak-gray;
    color: $weak-gray;
}

// 特典タグ
.a-tag-gift {
    @extend %tags-base--free;
    color: $gift-color;
    border-color: $gift-color;
}

// セット商品タグ
.a-tag-set {
    @extend %tags-base--free;
    color: $set-color;
    border-color: $set-color;
}

// 完結タグ
.a-tag-comp {
    @extend %tags-base;
    color: $base-link-color;
    border-color: $base-link-color;
}

// 電子書籍
.a-tag-ebook {
    @extend %tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// デジタルコンテンツ
.a-tag-digital {
    @extend %tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// 最新刊
.a-tag-latest {
    @extend %tags-base--free;
    color: $weak-gray;
    border-color: $border-color;
}

// 試読増量
.a-tag-increase {
    @extend %tags-base--free;
    color: $increase-color;
    border-color: $increase-color;
}

// 読み放題
.a-tag-sbsc {
    @extend %tags-base--free;
    position: relative;
    padding-left: 22px;
    border: none;
    color: #fff;
    background: $sbsc-tag-color;

    &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 4px;
        width: 15px;
        height: 12px;
        background-image: url(/anne/img/common/icon-sbsc-i.svg);
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
    }
}

// リスト用：NEWタグ
.a-tag-new {
    @extend %tags-base;
    color: $sale-color;
    border: transparent;
    font-size: 9px;
    background: $new-tag-color;
}

// リスト用：予約タグ
.a-tag-reserve {
    @extend %tags-base;
    border: transparent;
    color: #fff;
    background: $strong-blue;
}

// リスト用：SALEタグ
.a-tag-sale {
    @extend %tags-base;
    border: transparent;
    color: #fff;
    font-size: 9px;
    background: $sale-color;
}

// リスト用：コインアップタグ
.a-tag-coin-up {
    @extend %tags-base;
    justify-content: flex-start;
    position: relative;
    width: 50px;
    padding-left: 3px;
    background: $coin-up-tag-color;

    &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        margin-top: -1px;
    }

    &::before {
        top: 6px;
        right: 14%;
        width: 5px;
        height: 5px;
        background: #fff;
        transform: rotate(135deg);
    }

    &::after {
        top: 3px;
        right: 4px;
        border-style: solid;
        border-width: 0 8px 8px 0;
        border-color: transparent #fff transparent transparent;
    }
}

// 予約でコインUP!
.a-tag-coin-up--reserve {
    @extend %tags-base--free;
    background: $coin-up-tag-color;
    font-size: 10px;
}

// めがイラスト
.a-tag-mega-illust {
    @extend %tags-base--free;
    border-color: $base-color;
    color: $base-color;
}

// ステータスタグ
// ==============================================
// 購入・既読情報表示タグ（購入済み・登録済み・未読・既読）----
%status-tags-base {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    height: 18px;
    border: 1px solid $border-color;
    border-radius: 4px;
    background: #fff;
    overflow: hidden;

    .ico {
        display: inline-block;
        font-size: 12px;
        line-height: 16px;

        &::before {
            line-height: 16px;
        }
    }

    .ico-txt {
        display: inline-block;
        margin: 0 3px;
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
    }
}

// 購入済み
.a-tag-purchased {
    @extend %status-tags-base;
    color: $active-text-color;
}

// 登録済み
.a-tag-registered {
    @extend %status-tags-base;
    background: $status-tag-bg-color;
    color: $weak-gray;
}

// 読み放題
.a-tag-subscription {
    @extend %status-tags-base;
    color: $active-text-color;
}