// リンクリスト

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";
@import "../../molecules/list";

// ==============================================
// スタイル
// ==============================================

.m-side-link-list {
    @extend %m-text-list;
    font-weight: bold;
}