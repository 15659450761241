// キーワードリスト（タグリスト）
@import "../templates/layout/mixin";
@import "../templates/layout/font-variable";
@import "../atoms/colors";

.m-keyword-list {
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    margin-right: -8px;
    margin-bottom: -8px;

    li {
        flex-shrink: 0;
        width: fit-content;
        margin-right: 8px;
        margin-bottom: 8px;

        a {
            display: inline-block;
            padding: 8px 13px;
            border: 1px solid $border-color;
            border-radius: 24px;
            font-size: 12px;
            line-height: 1.3;
        }
    }
}

.m-keyword-list--check {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        margin: 12px 4px 0;
        @media  screen and (max-width: 360px){
            margin: 12px 2px 0;
        }

        a,
        button {
            position: relative;
            border: 1px solid $border-color;
            border-radius: 24px;
            color: $base-color;
            font-size: 12px;
            line-height: 1.3;
            padding: 8px 13px 8px 29px;
            @include opacity-hover;

            &::after {
                content: "";
                position: absolute;
                top: 9px;
                left: 13px;
                display: block;
                width: 12px;
                height: 12px;
                background: url(/anne/img/common/icon_check_off.svg) 0% 0%/12px 12px;
            }

            &.active {
                border-color: $base-color;
                font-weight: $font-bold;

                &::after {
                    background: url(/anne/img/common/icon_check_on.svg) 0% 0%/12px 12px;
                }
            }

            &:disabled {
                border-color: $disable-btn-text-color;
                color: $disable-btn-text-color;
                pointer-events: none;

                &::after {
                    background: url(/anne/img/common/icon_check_disable.svg) 0% 0%/12px 12px;
                }
            }
        }
    }
}
