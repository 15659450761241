// タイルリスト

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import "../molecules/tile";

// ==============================================
// スタイル
// ==============================================

// メインカラム以外の幅（右カラム + 各スペース）
$add-width: 226 + 15*3;
// タイルの最大個数
$tile-max-num: 8;
// タイルの最小幅
$tile-min-width: 150;
// タイルの間隔
$tile-margin: 8;

.m-tile-list {
    display: grid;
    grid-template-columns: repeat(8, minmax(150px, 1fr));
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    .m-tile {
        display: flex;

        .m-book-item {
            .m-book-item__info-block {

                // ジャンルタグ
                .m-book-item__tag-box {

                    // タグ本体
                    span {
                        width: 100%;
                    }
                }
            }
        }
    }

    @for $i from 1 through 3 {
        @media screen and (max-width: ((($tile-min-width + $tile-margin) * ($tile-max-num + 1 - $i)) - $tile-margin + $add-width) * 1px) {
            grid-template-columns: repeat($tile-max-num - $i, minmax(($tile-min-width - 10) * 1px, 1fr));
        }
    }
}

// 1枠表示のキャッチコピー有りの棚
.m-shelf-with-banner-normal .m-tile-list {
    grid-template-columns: repeat(7, minmax(150px, 1fr));
    @for $i from 1 through 3 {
        @media screen and (max-width: ((($tile-min-width + $tile-margin) * ($tile-max-num + 1 - $i)) - $tile-margin + $add-width) * 1px) {
            grid-template-columns: repeat($tile-max-num - $i - 1, minmax(($tile-min-width - 10) * 1px, 1fr));
        }
    }
}

// 2枠表示のキャッチコピー有りの棚
.m-shelf-with-banner-wide .m-tile-list {
    grid-template-columns: repeat(6, minmax(150px, 1fr));
    @for $i from 1 through 3 {
        @media screen and (max-width: ((($tile-min-width + $tile-margin) * ($tile-max-num + 1 - $i)) - $tile-margin + $add-width) * 1px) {
            grid-template-columns: repeat($tile-max-num - $i - 2, minmax(($tile-min-width - 10) * 1px, 1fr));
        }
    }
}
