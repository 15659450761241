// 書籍商品ブロック

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";
@import "thumb";

// ==============================================
// スタイル
// ==============================================

%m-book-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
    background-color: #ffffff;

    a:hover {
        text-decoration: none;
    }

    // ランクブロック
    // ============================================
    .m-book-item__rank-block {
        margin-bottom: 8px;
        [class^=a-rank-] {
            font-size: 13px;
            .ico {
                font-size: 13px;
            }
            .rank-txt-s {
                font-size: 13px;
            }
        }
    }

    // 書影ブロック
    // ============================================
    .m-book-item__thumb-block {
        position: relative;
        font-size: 0;
        text-align: center;

        // サムネイル
        .m-thumb {
            width: 100%;

            // ラベルエリア
            .m-thumb__label-area {
                position: absolute;
                top: -8px;
                bottom: 0;
                left: -8px;
                right: -8px;
                pointer-events: none;
                z-index: 1;
            }

            .m-thumb__image {
                img {
                    display: inline-block;
                    max-width: 100%;
                    max-height: 160px;
                    width: auto;
                }
            }

            // aタグの場合
            a.m-thumb__image {
                @include hover;
            }

            // 個別削除ボタンのタップ範囲・位置調整
            .a-close-btn-box {
                padding: 0 0 26px 26px;
                top: -4px;
                right: -3px;
            }

            // チェックボタン位置・タップ範囲調整
            .a-thumb-box__check {
                right: -6px;
                bottom: -8px;
            }
        }
    }

    // 情報ブロック
    // =============================================
    //閲覧履歴ページでタイル上部に日付と削除ボタンを入れるためのパーツ #43519
    .m-book-item__top-block{
        position: relative;
        font-size: 12px;
        text-align: center;
        padding-bottom:8px;

        .m-book-item__browsed-date{
            border-bottom: 1px solid $background-color;
            color: #666;
            font-size: 12px;
            text-align: center;
            padding: 3px 0 8px;
        }

        .a-delete-btn-box{
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            padding: 0 0 24px 24px;
            z-index: $delete-btn-z-index;
        }
    }

    .m-book-item__info-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        margin-top: 8px;

        // セカンダリー情報ブロック
        .m-book-item__secondary {
            display: grid;
            grid-row-gap: 4px;
            padding-bottom: 6px;
        }

        // プライマリー情報ブロック
        .m-book-item__primary {
            display: grid;
            grid-row-gap: 4px;
        }

        // ランキング
        .m-book-item__rank {
            line-height: 1;
            white-space: nowrap;
        }

        // ジャンルタグ
        .m-book-item__tag-box {
            display: grid;
            grid-template-columns: repeat(4,minmax(max-content,38px));
            grid-gap: 3px;
            font-size: 0;
        }

        // 作品タイトル（トップ）
        .m-book-item__title {
            display: -webkit-box;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.2;
            max-height: 2.75rem;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            white-space: normal;
            overflow: hidden;
            text-overflow: clip;
            word-break: break-all;

            // iOSでline-clampの表示対策（何かで囲っていないと3点リーダが中途半端な位置に出る）
            :first-child {
                &::before {
                    content: ""
                }

                &::after {
                    content: "";
                }
            }

            &:hover {
                text-decoration: underline;
            }
        }

        // 著者名
        .m-book-item__author {
            font-size: 10px;
            line-height: 1.2;
            color: $weak-gray;

             a {
                 @include textHover;
             }
        }

        // レーベル
        .m-book-item__label {
            font-size: 10px;
            line-height: 1.2;
            color: $weak-gray;
        }

        // パブリッシャー
        .m-book-item__publisher {
            font-size: 10px;
            line-height: 1.2;
            color: $weak-gray;
        }

        .m-book-item__summary {
            font-size: 10px;
            line-height: 1.5;
        }

        // 登録状況タグ
        .m-book-item__status-tag {
            text-align: right;
        }

        // シリーズ表示
        .m-book-item__series {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .ico {
                font-size: 14px;
            }

            .ico-txt {
                margin-left: 0.3em;
                font-size: 10px;
                font-weight: bold;
            }
        }

        // 価格周り
        .m-book-item__price-box {
            display: grid;
            grid-row-gap: 4px;
            text-align: right;

            // セール期間
            .m-book-item__period {
                font-size: 10px;
                font-weight: bold;
                color: $strong-red;
            }

            // 配信予定日
            .m-book-item__schedule {
                font-size: 10px;
                font-weight: bold;
                color: $strong-blue;
            }

            // 価格
            .m-book-item__price {
                font-weight: bold;
                color: $strong-red;

                .m-book-item__price-num {
                    font-size: 13px;
                }

                .m-book-item__price-unit {
                    margin-left: 0.1em;
                    font-size: 10px;
                    font-weight: normal;
                }

                .m-book-item__price-tax {
                    margin-left: 0.2em;
                    font-size: 10px;
                    font-weight: normal;
                    color: $base-text-color;
                }

                // 読み放題対象
                .m-book-item__price-subscription {
                    font-size: 11px;
                    color: $base-color;
                }
            }
        }

        // 注意文
        .m-book-item__notice {
            > p {
                font-size: 12px;
                line-height: 1.5;
            }

            // アイコンなし
            .m-book-item__notice--payment {
                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            // アイコンあり
            .m-book-item__notice-item {
                position: relative;
                padding-left: 20px;
                &:not(:last-of-type) {
                    margin-bottom: 0.5em;
                }
                &::before {
                    content: "\e940";
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-family: bookwalker;
                    font-size: 14px;
                    color: #FF9900;
                }
            }
        }

        // 注釈・赤字部分
        .m-book-item__annotation--red {
            color: $strong-red;
            margin-top: 8px;

            p {
                font-size: 14px;
            }
        }
    }

    // ボタン
    // ============================================
    .m-book-item__btn-box {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-column-gap: 4px;
        width: 100%;
        min-height: 42px;
        padding: 1px 1px;

        li {
            margin: 0;
            a {
                width: 100%;
                margin: 0;
            }
        }

        .a-purchase-AppleID-btn, .a-read-by-app-btn {
            margin: 0 2px 0 0;
        }

        // 閲覧日
        .o-tile-browsed-date {
            clear: both;
            display: block;
            width: 100%;
            padding: 0 2px 0 0;
            text-align: right;
            font-size: 9px;
            color: $weak-gray;
        }
    }

    // 拡大
    .m-book-item__zoom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 28px;
        border: 1px solid $border-color;
        border-radius: 20px;
        color: $base-link-color;
        margin: auto 0 0 auto;

        .ico {
            font-size: 14px;
        }

        .ico-txt,
        .button-text {
            font-size: 14px;
            font-weight: bold;
            margin-left: 0.3em;
        }
    }

    // 購入済み
    // ==============================================
    &.purchased {
        background-color: $purchased-color;
    }
}