// バナーリスト

// ==============================================
// 読み込み
// ==============================================

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-banner-list {
    display: flex;
    justify-content: center;

    .m-banner {
        display: flex;
        align-items: center;
        background-color: #f8f8f8;
        margin-right: 10px;
        width: 300px;

        &:last-of-type {
            margin-right: 0;
        }

        a {
            display: flex;
            height: 100%;
            align-items: center;

            &:hover {
                opacity: 0.6;
            }
        }

        img {
            width: 100%;
        }
    }
}