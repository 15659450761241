// トップ

// ==============================================
// 読み込み
// ==============================================
@import "../../sp/templates/layout/font-variable";
@import "../templates/layout/define";
@import "../atoms/buttons/button";

// ==============================================
// スタイル
// ==============================================

// 共通セクション（総合・館トップ等）
.o-side-section {
    margin-bottom: 14px;
    background-color: #ffffff;

    &:first-of-type {
        margin-top: 0;
    }

    // ヘッダー
    .o-side-section__header, %o-side-section__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 6px 0 8px;
        min-height: 40px;
        border-left: 8px solid $base-color;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px dotted $base-color;
        }

        // タイトル
        .o-side-section__title {
            font-size: 14px;
            font-weight: bold;
            color: $base-color;
            line-height: 1;
        }

        // もっと見る
        .o-side-section__more {
            @extend %a-rounded-btn--right-arrow;
            font-size: 10px;
        }
    }

    // ボディ（コンテンツ部分）
    .o-side-section__body {
        padding: 12px;

        &.unset {
            padding: unset;
        }
    }

    // フッターリンク
    .o-side-section__footer-link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 8px;
        min-height: 40px;
        border-top: 1px dotted $border-color;

        a {
            position: relative;
            padding-right: 18px;

            &::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                border-top: 2px solid $base-color;
                border-right: 2px solid $base-color;
                @include centering(y, 45);
                right: 2px;
            }
        }
    }
}

// セクションのグループ
.o-side-section-group {
    margin-bottom: 14px;
    background-color: #ffffff;
}

// 汎用ブロック（バナー等）
.o-side-block {
    margin-bottom: 14px;
}

// 旧構造のサイドカラムにのみに適用
#asideInner {
    .o-side-section {
        font-family: $base-font-family;
    }
}