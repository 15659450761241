@import "../colors";
@import "../z-index";
@import "../arrow-mixin";
@import "../../templates/layout/font-variable";
@import "../../templates/layout/mixin";
@import "close-btn";
@import "subscription-button";

// ==============================================
// ボタンの矢印
// ==============================================
%a-btn-arrow {
    &::before {
        content: '';
        display: block;
        position: absolute;
        @include centering(y, -45);
        width: 10px;
        height: 10px;
        border-right: 2px solid $base-color;
        border-bottom: 2px solid $base-color;
        box-sizing: border-box;
    }
}

// 左向き
%a-btn-arrow--left {
    @extend %a-btn-arrow;

    &::before {
        @include centering(y, 135);
    }
}

// 下向き
%a-btn-arrow--down {
    @extend %a-btn-arrow;

    &::before {
        @include centering(y, 45);
    }
}

// +と-の開閉ボタン
.a-plus-minus-btn {
    width: 16px;
    height: 16px;
    top: 43%;
    transform: translateY(-50%);

    &:before,
    &:after {
        content: "";
        position: absolute;
        background-color: $base-color;
    }

    /* Vertical line */
    &:before {
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        margin-left: -1px;
    }

    /* horizontal line */
    &:after {
        top: 43%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-bottom: -1px;
    }

    &:hover {
        cursor: pointer;
    }
}

.a-plus-minus-btn.a-plus-minus-btn--opened {
    &:before {
        transform: rotate(90deg);
    }

    &:after {
        display: none;
    }
}

// 上向き矢印・下向き矢印の開閉ボタン
.a-up-down-arrow-btn {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translateY(-50%);

    &::before {
        @include down-arrow($color: $base-color);
        @include centering(xy, 315);
        margin-top: -3px;
    }
}

.a-up-down-arrow-btn--opened {
    &::before {
        @include centering(xy, 135);
        margin-top: 2px;
    }
}

// 新規会員登録ボタン（横長・角丸）
.a-register-btn-wide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 44px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
    color: #fff;
    background: $sign-in-btn-color;
    box-shadow: 0 2px 0 $sign-in-btn-shadow-color;

    .ico {
        font-size: 16px;
        margin-right: 8px;
        vertical-align: middle;
    }

    &::after {
        @include right-arrow($color: #fff);
        width: 10px;
        height: 10px;
    }
}

// カード内・タイル内ボタン
// TODO: 書誌の購入等に関連するもの。増え過ぎたら分割する？？？

// ==========================================

// 試し読みボタン（40x40）
.a-trial-btn-s {
    @include button-base;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        padding: 4px 0 1px;
    }

    .ico-txt,
    .button-text {
        font-size: 9px;
        transform: scale(0.9);
    }
}

// まる読みボタン
.a-maruyomi-btn-s {
    @include button-base;
    border-bottom: 1.5px solid $maruyomi-btn-shadow-color;
    color: #fff;
    background: $maruyomi-btn-color;

    .ico {
        padding: 4px 0 1px;
    }

    .button-text {
        font-size: 9px;
        transform: scale(0.9);
    }
}

// 予約関連 -----------------------------------------
// 予約ボタン（40x40）
.a-reserve-btn-s {
    @include button-base;
    border-bottom: 1.5px solid #00254B;
    background: $strong-blue;

    .ico {
        padding: 5px 0 2px 0;
        font-size: 16px;
    }

    &--on {
        @extend .a-reserve-btn-s;
        border: 1px solid $strong-blue;
        color: $strong-blue;
        background: #fff;
    }
}

// 予約ボタン（80x40）
.a-reserve-btn {
    @extend .a-reserve-btn-s;
    width: $long-icon-size;

    &--on {
        @extend .a-reserve-btn-s--on;
        width: $long-icon-size;
    }
}

// キャンセル、iOS用予約済みテキスト
.a-reserve-btn__text {
    color: $strong-blue;
    font-size: 12px;
    line-height: 1.5;
    padding-top: 8px;
}

// シリーズ予約ボタン（80x40）
.a-series-reserve-btn {
    @extend .a-reserve-btn-s;
    width: $long-icon-size;

    &--on {
        @extend .a-reserve-btn-s--on;
        width: $long-icon-size;
    }
}

// シリーズ予約する ボタン（画面幅いっぱい）
.a-series-reserve-btn-wide {
    @include wide-button-base;
    border-bottom: 1.5px solid #00254B;
    background: $strong-blue;

    .ico {
        font-size: 20px;
    }
}

// 作品詳細ページで予約
.a-basic-btn--detail-reserve {
    @extend .a-series-reserve-btn-wide;

    .ico {
        font-size: 16px;
    }

    .ico-txt {
        font-size: 11px;
        margin-left: 24px;
    }
}

.a-series-reserve-btn-wide--on {
    @extend .a-series-reserve-btn-wide;
    border: 1px solid $strong-blue;
    color: $strong-blue;
    background: #fff;
}

// カート関連 -----------------------------------------
// カートボタン（40x40）
.a-cart-btn-s {
    @include button-base;
    border-bottom: 1.5px solid $cart-btn-shadow-color;
    background: $cart-btn-color;
}

// 見る
.a-cart-btn-s--on {
    @include button-base;
    border: 1px solid $cart-btn-color;
    border-radius: 4px;
    color: $cart-btn-color;
}

// カートボタン（80x40）
.a-cart-btn {
    @extend .a-cart-btn-s;
    width: $long-icon-size;
}

// カートを見る
.a-cart-btn--on {
    @extend .a-cart-btn-s--on;
    width: $long-icon-size;
}

// まとめてカートボタン（80x40）
.a-cart-all-btn {
    @extend .a-cart-btn-s;
    width: $long-icon-size;
}

.a-cart-all-btn--on {
    @extend .a-cart-btn-s--on;
    width: $long-icon-size;
}

// まとめてカートに入れるボタン（画面幅いっぱい）
.a-cart-all-btn-wide {
    @include wide-button-base;
    border-bottom: 1.5px solid $cart-btn-shadow-color;
    background: $cart-btn-color;
}

// 今すぐ購入 -----------------------------------------
// 今すぐ購入ボタン（40x40）
.a-purchase-btn-s {
    @include button-base;
    border-bottom: 1.5px solid #B25D08;
    background: $purchase-btn-color;

    .ico {
        padding: 6px 0 2px;
        font-size: 15px;
    }

    .ico-txt {
        font-size: 11px;
    }
}

// 今すぐ購入ボタン（80x40）
.a-purchase-btn {
    @extend .a-purchase-btn-s;
    width: $long-icon-size;
}

// AppleID購入ボタン（80x40）
.a-purchase-AppleID-btn {
    @include button-base;
    width: $long-icon-size;
    border-bottom: 1.5px solid #B25D08;
    background: $purchase-btn-color;

    .ico-txt,
    .button-text {
        font-size: 9px;
        transform: scale(0.9);
    }
}

// まる読みボタン -----------------------------------------
.a-maruyomi-btn {
    @include button-base;
    width: $long-icon-size;
    background: $maruyomi-btn-color;
    border-bottom: 1.5px solid $maruyomi-btn-shadow-color;

    .ico {
        font-size: 19px;
        padding: 4px 0 1px;
    }
}

// 無料関連 -----------------------------------------
// 無料で読むボタン（40x40）
.a-free-btn-s {
    @include button-base;
    border-bottom: 1.5px solid #9F0505;
    background: $free-key-color;

    .ico {
        padding: 4px 0 1px;
        font-size: 19px;
    }
}

// 無料で読むボタン（80x40）
.a-free-btn {
    @extend .a-free-btn-s;
    width: $long-icon-size;
}

// アプリで読むボタン（40x40）
.a-read-by-app-btn-s {
    @include button-base;
    padding-top: 3px;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        padding: 1px 0 0 0;
    }

    .ico-txt {
        padding: 0;
    }
}

// アプリで読むボタン（80x40）
.a-read-by-app-btn {
    @extend .a-read-by-app-btn-s;
    width: $long-icon-size;
}

// （ブラウザで）読むボタン（40x40）
.a-read-by-browser-btn-s {
    @extend .a-read-by-app-btn-s;

    .ico {
        padding: 0 0 1px 0;
        font-size: 20px;
    }
}

// （ブラウザで）読むボタン（80x40）
.a-read-by-browser-btn {
    @extend .a-read-by-browser-btn-s;
    width: $long-icon-size;
}

// 追加
.a-add-btn-s {
    @include button-base;
    padding-top: 2px;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        padding: 1px 0 1px 0;
    }

    .ico-txt {
        padding: 0;
    }
}

// 追加済
.a-add-btn-s--on {
    @extend .a-add-btn-s;
    border: 1px solid $strong-green;
    color: $strong-green;
}

// 拡大表示
.a-zoom-btn-s {
    @include button-base;
    padding-top: 2px;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        padding: 1px 0 1px 0;
    }

    .ico-txt {
        padding: 0;
        font-size: 9px;
        transform: scale(0.9);
    }
}

// コード入力ボタン
.a-code-btn {
    @include button-base;
    width: $long-icon-size;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        font-size: 19px;
        padding: 3px 0 2px;
    }
}

// ダウンロードボタン
.a-download-btn {
    @include button-base;
    width: $long-icon-size;
    border: 1px solid $base-color;
    color: $base-color;

    .ico {
        font-size: 19px;
        padding: 3px 0 2px;
    }
}


// 話・連載ストアへ移動
.a-transition-warensai-btn-s {
    @include button-base;
    padding-top: 3px;
    border: none;
    background-color: $ttsk-blue-color;
    color: #fff;

    .ico {
        padding: 1px 0 0 0;
    }

    .ico-txt,
    .button-text {
        padding: 0;
    }
}

// （ブラウザで）読むボタン（80x40）
.a-transition-warensai-btn {
    @extend .a-transition-warensai-btn-s;
    width: $long-icon-size;
}

// 絞り込みボタン
.a-filter-btn {
    @include button-base;
    width: 46px;
    height: 44px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    color: $base-color;

    .ico {
        padding: 6px 0 4px;
        font-size: 1.8rem;
    }

    .ico-txt {
        font-size: 1rem;
        font-weight: bold;
    }
}

// 絞り込みボタン(非アクティブ)
.a-filter-btn--disabled {
    @extend .a-filter-btn;
    color: $border-color;
}

// 並べ替えボタン
.a-sort-btn {
    @extend .a-filter-btn;
}

// 更新ボタン
.a-refresh-btn {
    @extend .a-filter-btn;

    .ico {
        padding: 6px 0 3px;
        font-size: 1.9rem;
    }
}

//編集ボタン
.a-edit-btn {
    @extend .a-filter-btn;

    .ico {
        padding: 4px 0 2px 2px;
        font-size: 2rem;
    }
}

// 閉じるボタン（46x44）
.a-close-btn-m {
    @extend .a-filter-btn;

    .ico {
        padding: 1px 0 0 2px;
        font-size: 2.8rem;
    }

    .ico-txt {
        margin-top: -1px;
    }
}

// 編集ボタン(非アクティブ)
.a-edit-btn--disabled {
    @extend .a-edit-btn;
    color: $border-color;
}

// 完了ボタン 40x40
.a-finish-btn-s {
    @extend .a-filter-btn;
    color: $strong-green;

    .ico-txt {
        font-size: 14px;
        line-height: 40px;
    }
}

// 全削除ボタン
.a-clear-btn {
    @include button-base;
    width: 46px;
    height: 44px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    color: $strong-red;

    .ico {
        padding: 3px 0 0 0;
        font-size: 24px;
    }

    .ico-txt {
        font-size: 11px;
    }
}

// 全削除ボタンボタン(非アクティブ)
.a-clear-btn--disabled {
    @extend .a-clear-btn;
    color: $border-color;
}

// 個別削除ボタン 位置調整
.a-close-btn-box {
    z-index: $close-btn-z-index;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
}

// モーダル用の閉じるボタン
.a-modal-close-btn-box {
    z-index: $close-btn-z-index;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
}

.a-modal-close-btn {
    @include button-base;
    width: 18px;
    height: 18px;
    border: 1px solid $base-color;
    background: #fff;
    color: $base-color;

    .ico {
        padding: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
    }
}

// （チェックを入れたものを）削除ボタン
.a-delete-btn {
    @include button-base;
    width: 80px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    color: $strong-red;
    cursor: pointer;

    .ico {
        display: inline-block;
        padding: 0 2px 0 0;
        font-size: 22px;
        vertical-align: middle;
        line-height: 40px;
    }

    .ico-txt, .button-text {
        display: inline-block;
        font-size: 14px;
        vertical-align: middle;
        line-height: 40px;
    }
}

// チェックボタン（書影右下）
.a-label-check {
    position: absolute;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0;
    display: block;
    z-index: $label-z-index;
    background-image: url(/anne/img/common/btn-check-off.svg);
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
    right: 3px;
    bottom: 3px;
}

.a-label-check--on {
    @extend .a-label-check;
    background-image: url(/anne/img/common/btn-check-on.svg);
}

// チェックボタン
.a-thumb-box__check {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    //タップ範囲44x44
    padding: 44px 0 0 44px;
    z-index: $thumb-check-z-index;
}

// 著者をフォローボタン
.a-follow-author-btn {
    display: block;
    padding: 6px 0;
    border-radius: 4px;
    border: 1px solid $border-color;
    text-align: center;
    color: $base-color;
    background: #fff;
    box-shadow: 0 1.5px 0 rgba(#000, .35);

    .ico {
        margin: 0 6px 0 0;
        vertical-align: middle;
        font-size: 18px;
    }

    .ico-txt {
        font-size: 12px;
        vertical-align: middle;
        font-weight: 700;
    }
}

// 著者をフォロー済み
.a-follow-author-btn--on {
    @extend .a-follow-author-btn;
    color: $active-text-color;

    .ico {
        border-color: $active-color;
    }
}

// レーベルをフォローボタン
.a-follow-label-btn {
    @extend .a-follow-author-btn;
}

.a-follow-label-btn--on {
    @extend .a-follow-author-btn--on;
}

// もっと見るボタン
.a-read-more-btn {
    display: block;
    position: relative;
    padding: 8px;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    border: 1px solid $border-color;
    border-radius: 4px;
    box-shadow: 0 1.5px $border-color;
    background: #fff;
}

// 「▼もっと見る」ボタン(もっと見るボタンより縦に短い)
.a-read-more-btn-s {
    display: block;
    position: relative;
    padding: 4px 8px 4px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    border: 1px solid $border-color;
    box-shadow: 0 1.5px $border-color;
    background: #fff;

    &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 4px 0 0;
        border-style: solid;
        border-width: 9px 5px 0 5px;
        border-color: $base-color transparent transparent transparent;
    }
}

// 「▲閉じる」ボタン（「▼もっと見る」押下後閉じる用）
.a-fold-btn {
    @extend .a-read-more-btn-s;

    &::before {
        border-width: 0 5px 9px 5px;
        border-color: transparent transparent $base-color transparent;
    }
}

// ページ遷移ボタン（テキスト+ 右向き矢印）
.a-page-transition-btn {
    position: relative;
    display: block;
    padding: 8px 32px 8px 16px;
    border-radius: 4px;
    border: 1px solid $border-color;
    font-size: 13px;
    font-weight: 700;
    color: $base-link-color;
    background: #fff;

    &::after {
        @include right-arrow($color: $base-link-color);
        width: 10px;
        height: 10px;
    }
}

// チェックボックスボタン
input.a-square-check-box, %a-square-check-box {
    position: relative;
    width: 22px;
    height: 22px;
    border: 2px solid $border-color;
    background: #fff;
    color-scheme: only light;

    // チェックマーク
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 5px;
        transform: rotate(45deg);
        width: 8px;
        height: 12px;
        border-right: 2px solid #eef0f2;
        border-bottom: 2px solid #eef0f2;
        color-scheme: only light;
    }

    // チェック時
    &:checked {
        background: $active-color;
        color-scheme: only light;

        &::after {
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            color-scheme: only light;
        }
    }
}

// チェックボックスボタンのシンプル版
input.a-square-check-box--simple {
    @extend %a-square-check-box;

    // チェック時
    &:checked {
        background: #fff;

        &::after {
            border-right: 2px solid $active-color;
            border-bottom: 2px solid $active-color;
        }
    }
}

// アイコン付きボタン（上アイコン下テキスト）
.a-icon-btn, %a-icon-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 65px;
    height: 40px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid $border-color;
    color: $base-color;

    .ico {
        font-size: 18px;
    }

    .ico-txt,
    .button-text {
        font-size: 10px;
        font-weight: bold;
        line-height: 1;
    }

    &.disable {
        color: $disable-btn-text-color;
        background-color: $disable-btn-bg-color;
        box-shadow: none;

        span, a {
            color: $disable-btn-text-color;
        }
    }

    &.on {
        display: flex;
    }

    &.off {
        display: none;
    }
}

// アイコン付きボタン 中サイズ（上アイコン下テキスト）
.a-icon-middle-btn, %a-icon-middle-btn {
    @extend %a-icon-btn;
    width: 68px;
    height: 44px;
    box-shadow: 0 1px $border-color;

    .ico-txt, .button-text {
        font-size: 12px;
    }

    cursor: pointer;
}

// 全選択ボタン
.a-icon-middle-btn--all-select {
    @extend %a-icon-middle-btn
}

// 全解除ボタン
.a-icon-middle-btn--all-unselect {
    @extend %a-icon-middle-btn
}

// 削除ボタン
.a-icon-middle-btn--delete {
    @extend %a-icon-middle-btn;
    color: $strong-red;
    .ico {
        font-size: 20px;
    }

    // FIXME: a-icon-btnの&.disabledのところのコピペ。 a-icon-btnの側に持っていけるならそちらの方がいい
    &:disabled, &.disabled {
        color: $disable-btn-text-color;
        background-color: $disable-btn-bg-color;
        box-shadow: none;
        cursor: default;

        span, a {
            color: $disable-btn-text-color;
        }
    }
}

// チェックリストへ移動ボタン
.a-check-list-btn {
    @include button-base;
    width: 180px;
    border: 1px solid $border-color;
    box-shadow: 0 1px $border-color;
    color: $base-color;

    .ico {
        display: inline-block;
        margin-top: -0.2em;
        padding: 0 2px 0 0;
        font-size: 20px;
        vertical-align: middle;
        line-height: 38px;
    }

    .ico-txt {
        display: inline-block;
        font-size: 14px;
        vertical-align: middle;
        line-height: 38px;
    }
}

// 標準ボタン
.a-basic-btn {
    @include basic-button-base;
    color: $base-color;

    // 右向き矢印
    &.forward {
        min-height: 40px;
        height: auto;
        padding: 10px 32px 10px 14px;

        @include pc {
            padding-right: 34px;
        }

        &::before {
            @extend %a-btn-arrow;
            right: 12px;
        }
    }

    // 左向き矢印
    &.back {
        min-height: 40px;
        height: auto;
        padding: 10px 14px 10px 32px;

        @include pc {
            padding-left: 34px;
        }

        &::before {
            @extend %a-btn-arrow--left;
            left: 12px;
        }
    }

    // 下向き矢印
    &.down {
        min-height: 40px;
        height: auto;
        padding: 10px 32px 10px 14px;

        @include pc {
            padding-right: 34px;
        }

        &::before {
            @extend %a-btn-arrow--down;
            top: 42%;
            right: 12px;
        }
    }
}

// 標準ボタン（高さ34px）
.a-basic-low-btn {
    @include basic-button-base;
    height: 34px;
    color: $base-color;
}

// 標準ボタン　第1カラー
.a-basic-btn--primary {
    @include basic-color-button-base;
    background-color: $primary-color;
}

// 標準ボタン　第2カラー
.a-basic-btn--secondary {
    @include basic-color-button-base;
    background-color: $secondary-color;
}

// 標準ボタン　第2カラー（反転カラー）
.a-basic-btn--secondary-rev {
    @include basic-color-button-base;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    box-shadow: none;
}

// ギフト　標準ボタン
.a-basic-btn--gift {
    @include basic-color-button-base;
    background-color: $gift-btn-color;
}

// 予約　標準ボタン
.a-basic-btn--reserve {
    @include basic-color-button-base;
    background-color: $strong-blue;
}

// 予約済み（反転カラー）　標準ボタン
.a-basic-btn--reserve-rev {
    @include basic-color-button-base;
    border: 2px solid $strong-blue;
    color: $strong-blue;
    box-shadow: none;
}

// 予約　標準ボタン
.a-basic-btn--maruyomi {
    @include basic-color-button-base;
    background-color: $maruyomi-btn-color;
    box-shadow: 0 1px $maruyomi-btn-shadow-color;
}

// 話読みで読むのリード 標準ボタン
.a-basic-btn--warensai {
    @include basic-color-button-base;
    background-color: $ttsk-blue-color;
    box-shadow: 0 1px 0 0 $ttsk-dark-blue-color;

    .ico-txt {
        vertical-align: middle;
        font-weight: bold;
    }
}

// 新規会員登録ボタン（四角）
.a-basic-btn--member-registration {
    @include basic-color-button-base;
    background-color: $sign-in-btn-color;

    &:active, &:focus, &:visited, &:link {
        color: #fff;
    }
}

// はじめての方へボタン（白抜き）
.a-basic-btn--beginner {
    @include basic-color-button-base;
    border: 1px solid #75af3a;
    color: $cart-btn-color;
    box-shadow: none;
}

// 長丸ボタン
@mixin a-rounded-btn($background-color, $text-color) {
    display: block;
    position: relative;
    height: 52px;
    width: 100%;
    border-radius: 26px;
    background-color: $background-color;
    color: $text-color;
    font-size: 16px;
    font-weight: $font-bold;
    line-height: 1.5;
    text-align: center;
    @include opacity-hover;
}

// 長丸ボタン（紺）
.a-rounded-btn--navy {
    @include a-rounded-btn($base-color, #fff);
}

// 長丸ボタン（disable）
.a-rounded-btn--disable {
    @include a-rounded-btn($disable-btn-text-color, #fff);

    &:disabled {
        pointer-events: none;
    }
}

// 右矢印アイコン付長丸ボタン(塗りつぶしあり)
%a-rounded-btn--right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 24px;
    background-color: $base-color;
    border-radius: 24px;
    font-weight: bold;
    color: #fff;
    background-color: $base-color;
    color: #fff;
    padding: 0 24px 0 16px;
    word-break: keep-all;

    &::after {
        @include right-arrow($color: #fff);
        right: 9px;
    }
}

.a-rounded-btn--right-arrow {
    @extend %a-rounded-btn--right-arrow;
    font-size: 10px;
}

// 右矢印アイコン付長丸ボタン(塗りつぶしなし)
%a-rounded-btn--right-arrow-border {
    @extend %a-rounded-btn--right-arrow;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;

    &::after {
        @include right-arrow($color: #fff);
        right: 9px;
    }
}

.a-rounded-btn--right-arrow-border {
    @extend %a-rounded-btn--right-arrow-border;
    font-size: 10px;
}