// ==============================================
// スライドバナー
// ==============================================
@import "../../sp/atoms/colors";
@import "../../pc/atoms/buttons/swiper-button";

//タテスクのbaseTransitionと同じ
@mixin baseTransition {
    transition: all 0.3s ease-in-out;
}

// swiperとバナー横並べの切り替えがあるページで使用
@mixin banner-wrap {
    display: flex;
    justify-content: center;
    min-height: 200px;
    animation: load-banner-fadeIn 1s ease;
    padding: 2px 0;
}

@mixin banner {
    flex: 1;
    position: relative;
    max-width: 450px;
    background-color: #f8f8f8;
    margin-right: 2px;

    &:last-of-type {
        margin-right: 0;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border: 2px solid transparent;
        box-sizing: border-box;
        transition: border-color 0.2s  ease-in;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        z-index: 2;
    }

    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }

    // 遅延画像
    .swiper-lazy:not(.swiper-lazy-loaded) {
        display: none;
    }
}

$bullet-size: 6px;
$pagination-bullet-padding: 12px;
$break-point-up: 767.98px;
$slide-border: 2px;
$pagination-height: $pagination-bullet-padding * 2 + $bullet-size;

.m-swiper {
    .swiper-container {
        opacity: 0;
        //min-height: 232px;
        padding: $slide-border 0 $pagination-height;

    }

   .swiper-container-initialized {
       opacity: 1;
       transition: opacity 1s ease;
   }

    .swiper-wrapper {
        min-height: calc(100vw * ( 133/375 ));
        margin: 0 auto;
        @media screen and (min-width: $break-point-up) {
            min-height: 200px;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: auto;
            background-color: #f8f8f8;

            @media screen and (min-width: $break-point-up) {
                max-width: 450px;
            }

            a {
                display: flex;
                align-items: center;
                height: 100%;
                z-index: 2;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -$slide-border;
                    left: -$slide-border;
                    width: calc(100% + #{$slide-border} * 2);
                    height: calc(100% + #{$slide-border} * 2);
                    border: 2px solid transparent;
                    box-sizing: border-box;
                    transition: border-color 0.2s  ease-in;
                }

                &:hover::after {
                    border: 2px solid $base-color;
                }
            }

            img {
                max-width: 100%;
                height: auto;
                z-index: 3;
            }

            // 遅延画像
            .swiper-lazy:not(.swiper-lazy-loaded) {
                display: none;
            }
        }
    }

    // Preloader上書き
    .swiper-lazy-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: none;
        background: none;
        border: none;
        border-radius: unset;
        margin: 0;
        z-index: unset;
    }
    .swiper-lazy-preloader:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: none;
    }

    .a-swiper-button--prev,
    .a-swiper-button--next {
        top: calc((100% - #{$pagination-height} + #{$slide-border}) / 2);
        transform: translateY(-50%);
    }
}

//pagination
.m-swiper-pagination.swiper-pagination-bullets {
    max-height: $pagination-height;
    left: 0;
    bottom: 0;

    .swiper-pagination-bullet {
        position: relative;
        width: $bullet-size + 2px;
        height: $bullet-size + 2px;
        opacity: 1;
        background-color: transparent;
        box-sizing: content-box;
        margin: 0 2px;
        padding: $pagination-bullet-padding 2px;
        @include baseTransition;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: $bullet-size;
            height: $bullet-size;
            background-color: #ccc;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            @include baseTransition;
        }

        &:hover::before {
            background-color: $base-color;
        }
    }

    .swiper-pagination-bullet-active {
        width: 16px;

        &::before {
            width: 16px;
            height: $bullet-size + 2px;
            background-color: $base-color;
            border-radius: 100vh;
        }
    }
}

@keyframes load-banner-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
