// 書影周り

@import "../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-thumb {
    display: inline-block;

    // リボン型ラベル：ベース
    %a-label-ribbon-base {
        background-size: 34px 34px;
        width: 34px;
        height: 34px;
        top: 0;
        right: 0;
    }

    // 丸型シール：ベース
    %a-label-round-base {
        background-size: 28px 28px;
        width: 28px;
        height: 28px;
        top: -4px;
        left: -4px;
    }

    // 横長ラベル：ベース
    %a-label-square-horizon {
    }

    // 縦長ラベル：ベース
    %a-label-square-vertical {

    }

    .m-thumb__image {
        position: relative;

        .a-ttsk-label-logo {
            position: absolute;
            background: url(/anne/img/ttsk/logo-ttsk.svg) no-repeat 0 0;
            background-size: 100% auto;
            z-index: $ttsk-label-z-index;
            bottom: 1px;
            left: 2px;
            width: 28%;
            padding-top: 25.638%;
        }
    }

    // チェック
    .m-thumb__check {
        position: absolute;
        right: -8px;
        bottom: -8px;
        width: 44px;
        height: 44px;
        pointer-events: auto;
        @include hover;

        .a-check,
        .a-check--on {
            position: absolute;
            right: 3px;
            bottom: 3px;
        }
    }

    // newアイコンサイズ調節
    .a-label-new {
        @extend %a-label-ribbon-base;
    }

    // 予約アイコンサイズ調節
    .a-label-reserve {
        @extend %a-label-ribbon-base;
    }

    // セット商品サイズ調節
    .a-label-set {
        @extend %a-label-ribbon-base;
    }


    // \0アイコンサイズ調節
    .a-label-free {
        @extend %a-label-round-base
    }

    // SALEアイコンサイズ調節
    .a-label-sale {
        @extend %a-label-round-base
    }


    // 試し読み増アイコン-リボンの下
    .a-label-inc-trial {
        background-size: 26px 26px;
        width: 26px;
        height: 26px;
    }

    // コインアップ1・2・3size調節
    .a-label-coin-up-1 {
        background-size: 14px 39px;
        width: 14px;
        height: 39px;
    }

    .a-label-coin-up-2 {
        background-size: 14px 48px;
        width: 14px;
        height: 48px;
    }

    .a-label-coin-up-3 {
        background-size: 14px 57px;
        width: 14px;
        height: 57px;
    }
}

