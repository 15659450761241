// SNS公式アカウント（サイドカラム）

// ==============================================
// 読み込み
// ==============================================

@import "../../templates/layout/define";

// ==============================================
// スタイル
// ==============================================

.m-side-sns {
    .m-side-sns__icons {
        display: flex;

        li:not(:last-of-type) {
            margin-right: 13px;
        }

        a {
            display: block;
            &:hover {
                opacity: 0.6;
            }
        }
        img {
            width: 30px;
            height: 30px;
        }
    }

    .a-small-text-link {
        margin-top: 12px;
    }
}